export const editPencil = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.05 212.34" style={{ height: "20px", width: "20px" }}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_2-2" data-name="Layer 2">
        <path d="M173.33,83.35,58.28,198.4l-.19-.19a3.11,3.11,0,0,1-.46.16L3.5,212.25a2.8,2.8,0,0,1-3.41-3.41L14,154.71a2.09,2.09,0,0,1,.16-.44l-.49-.49L128.71,38.72Z" />
        <path
          d="M169.89,6.84h22.47a20.32,20.32,0,0,1,20.32,20.32V55a0,0,0,0,1,0,0H149.57a0,0,0,0,1,0,0V27.16A20.32,20.32,0,0,1,169.89,6.84Z"
          transform="translate(74.92 -119.01) rotate(45)"
        />
      </g>
    </g>
  </svg>
);

export const xSign = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Group_647" data-name="Group 647" transform="translate(-386 -241)">
      <circle
        id="Icon"
        className="cls-1"
        style={{ fill: "#ac1b1b" }}
        cx="12"
        cy="12"
        r="12"
        transform="translate(386 241)"
      />
      <path
        id="Line"
        className="cls-2"
        style={{ fill: "#fff" }}
        d="M.293,1.707A1,1,0,0,1,1.707.293l8,8A1,1,0,0,1,8.293,9.707Z"
        transform="translate(393 248)"
      />
      <path
        id="Line-2"
        data-name="Line"
        className="cls-2"
        style={{ fill: "#fff" }}
        d="M-.293,1.707a1,1,0,0,0,0-1.414,1,1,0,0,0-1.414,0l-8,8a1,1,0,0,0,0,1.414,1,1,0,0,0,1.414,0Z"
        transform="translate(403 248)"
      />
    </g>
  </svg>
);

export const checkCircle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.33"
    height="31.537"
    viewBox="0 0 32.33 31.537"
  >
    <g
      id="Icon_feather-check-circle"
      data-name="Icon feather-check-circle"
      transform="translate(2.003 2.017)"
    >
      <path
        id="Path_45"
        data-name="Path 45"
        class="cls-1"
        style={{
          fill: "none",
          stroke: "#2F5C27",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "4px",
        }}
        d="M30.5,15.483v1.265A13.749,13.749,0,1,1,22.345,4.182"
        transform="translate(-3 -2.991)"
      />
      <path
        id="Path_46"
        data-name="Path 46"
        class="cls-1"
        style={{
          fill: "none",
          stroke: "#2F5C27",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "4px",
        }}
        d="M31.374,6,17.625,19.763,13.5,15.638"
        transform="translate(-3.876 -3.242)"
      />
    </g>
  </svg>
);

export const warningSign = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 473.931 473.931">
    <circle style={{fill: "#fff3cd"}} cx="236.966" cy="236.966" r="236.966" />
    <path
      d="M214.399,252.389l-6.698-100.159c-1.257-19.517-1.871-33.526-1.871-42.027
c0-11.57,3.035-20.602,9.085-27.072c6.065-6.499,14.054-9.74,23.94-9.74c11.996,0,20.022,4.15,24.056,12.445
c4.034,8.303,6.065,20.258,6.065,35.857c0,9.205-0.494,18.559-1.459,28.022l-8.995,103.089c-0.973,12.277-3.061,21.68-6.279,28.239
c-3.207,6.544-8.509,9.815-15.888,9.815c-7.536,0-12.756-3.158-15.682-9.512C217.744,275.016,215.645,265.351,214.399,252.389z
M237.609,389.974c-8.501,0-15.936-2.739-22.267-8.251c-6.346-5.497-9.512-13.197-9.512-23.102c0-8.647,3.035-16.004,9.085-22.069
c6.065-6.065,13.493-9.092,22.275-9.092c8.786,0,16.269,3.027,22.477,9.092c6.204,6.065,9.31,13.425,9.31,22.069
c0,9.751-3.136,17.414-9.418,22.997C253.291,387.19,245.976,389.974,237.609,389.974z"
    />
  </svg>
);

export const refreshIcon = (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 492.883 492.883" style={{width: "40px", height: "40px", fill: "#525e8b"}}>
<g>
	<g>
		<path d="M122.941,374.241c-20.1-18.1-34.6-39.8-44.1-63.1c-25.2-61.8-13.4-135.3,35.8-186l45.4,45.4c2.5,2.5,7,0.7,7.6-3
			l24.8-162.3c0.4-2.7-1.9-5-4.6-4.6l-162.4,24.8c-3.7,0.6-5.5,5.1-3,7.6l45.5,45.5c-75.1,76.8-87.9,192-38.6,282
			c14.8,27.1,35.3,51.9,61.4,72.7c44.4,35.3,99,52.2,153.2,51.1l10.2-66.7C207.441,421.641,159.441,407.241,122.941,374.241z"/>
		<path d="M424.941,414.341c75.1-76.8,87.9-192,38.6-282c-14.8-27.1-35.3-51.9-61.4-72.7c-44.4-35.3-99-52.2-153.2-51.1l-10.2,66.7
			c46.6-4,94.7,10.4,131.2,43.4c20.1,18.1,34.6,39.8,44.1,63.1c25.2,61.8,13.4,135.3-35.8,186l-45.4-45.4c-2.5-2.5-7-0.7-7.6,3
			l-24.8,162.3c-0.4,2.7,1.9,5,4.6,4.6l162.4-24.8c3.7-0.6,5.4-5.1,3-7.6L424.941,414.341z"/>
	</g>
</g>
</svg>
)