import { useEffect } from "react";

const SearchBoxLogic = (setRequestObject, activeFiltersHandler) => {
  let timer;
  const waitTime = 500;

  const search = (text) => {
    setRequestObject((prevState) => {
      return {
        ...prevState,
        offset: 0,
        limit: 20,
        search: { searchValue: text },
      };
    });
    activeFiltersHandler({
      reqType: "search",
      filterType: text,
    });
  };

  const handleKeyUp = (e) => {
    const text = e.currentTarget.value;
    clearTimeout(timer);

    timer = setTimeout(() => {
      search(text);
    }, waitTime);
  };

  useEffect(() => {
    const input = document.querySelector("#search-input");
    input.addEventListener("keyup", handleKeyUp);

    return () => input.removeEventListener("keyup", handleKeyUp);
  }, [handleKeyUp]);

  return {};
};

export default SearchBoxLogic;
