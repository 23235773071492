import React from "react";
import titleStyles from "../PageHeadLine/PageHeadLine.module.css";

const AutoAllocationProgressBar = ({ autoAllocateProgressPercentage }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 className={titleStyles.PageHeadLine_title} style={{ width: "150px" }}>
          {"Progress"}
        </h1>
        <div
          style={{
            width: "50%",
            borderRadius: "10px",
            backgroundColor: "grey",
          }}
        >
          <div
            style={{
              width: autoAllocateProgressPercentage,
              height: "30px",
              borderRadius: "10px",
              backgroundColor: "#598ff8",
              textAlign: "center",
              lineHeight: "30px",
              color: "white",
              padding: "0 10px",
            }}
          >
            {autoAllocateProgressPercentage}
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoAllocationProgressBar;
