import { useContext, useState } from "react";
import { ReservationContext } from "../../../../context/ReservationContext";

import { SelectionContext } from "../../../../context/SelectionContext";
import Availability from "../../../Availability/Availability";

import styles from "./ItineraryDropDowns.module.css";

const initialItinerarySelectInfo = {
  txt: "Select",
  icon: "/img/icons/drop-down-arrow.svg",
}

const ItineraryDropDownLogic = (
  id,
  label,
  facilityData,
  availability,
  isOdd,
  quantity
) => {
  const [selectedBox, setSelectedBox] = useState(null);
  const { getSelection } = useContext(SelectionContext);
  const { selectedReservation } = useContext(ReservationContext);
  const selectedBoxHandler = (value) => {
    if (selectedBox === value) {
      setSelectedBox(null);
      return;
    }

    setSelectedBox(value);
  };

  const isNonSelectableDate = (diningType, date) => {
    try {
      const arrivalDate = selectedReservation.ArrivalDate.split("T")[0];
      const departureDate = selectedReservation.DepartureDate.split("T")[0];

      if (diningType === "Breakfast" && arrivalDate === date) {
        return true;
      } else if (diningType === "Dinner" && departureDate === date) {
        return true;
      }
      return false;
    } catch (error) {
      console.error(
        `${new Date()} Error in isNonSelectableDate func in ItineraryDropDownLogic.js file ${
          error.message
        }`
      );
    }
  };

  const contructSelectedTextDisplayed = (
    selectedBox,
    itineraryDay,
    typeLabel
  ) => {
    try {
      const { Date } = itineraryDay;
      const isNonSelectable = isNonSelectableDate(typeLabel, Date);
      if (isNonSelectable) {
      }

      const currentSelection = getSelection(Date, typeLabel);

      const isSkiped = selectedReservation[typeLabel] && !itineraryDay.Id;

      let itinerarySelectInfo = initialItinerarySelectInfo

      if (selectedBox === Date) {
        itinerarySelectInfo = {
          txt: "Selecting...",
          icon: "/img/icons/drop-down-arrow.svg",
        };
      } else if (
        currentSelection &&
        currentSelection.id &&
        currentSelection.Date
      ) {
        itinerarySelectInfo = {
          txt: currentSelection.Date.split("T")[1].substr(0, 5),
          icon: "/img/icons/ui/edit.svg",
        };
      } else if (isNonSelectable) {
        itinerarySelectInfo = initialItinerarySelectInfo
      } else if (isSkiped) {
        itinerarySelectInfo = {
          txt: "Not Required",
          icon: "/img/icons/drop-down-arrow.svg",
        };
      }

      return (
        <>
          <div
            className={styles.ItineraryDropDowns__selectionDisplay}
            onClick={selectedBoxHandler.bind(this, itineraryDay?.Date)}
          >
            <p className={styles.ItineraryDropDowns__selectedText}>
              {itinerarySelectInfo.txt}
            </p>
            <img
              src={itinerarySelectInfo.icon}
              alt="no img"
              className={`${styles.ItineraryDropDowns_icon} ${
                selectedBox === itineraryDay?.Date
                  ? styles.ItineraryDropDowns_icon_rotate
                  : null
              }`}
            />
          </div>
          {selectedBox === itineraryDay?.Date ? (
            <Availability
              id={id}
              availability={availability}
              facilityData={facilityData}
              dateSelected={itineraryDay.Date}
              typeSelected={label}
              isOdd={isOdd}
              selResQuantity={quantity}
              isSkiped={isSkiped}
            />
          ) : null}
        </>
      );
    } catch (error) {
      console.log(error);
    }
  };

  return { selectedBox, contructSelectedTextDisplayed, isNonSelectableDate };
};

export default ItineraryDropDownLogic;
