import React, { useContext, useEffect, useRef } from "react";

import { ReservationContext } from "../../../../context/ReservationContext";
import { HelperContext } from "../../../../context/HelperContext";
import {
  constuctCommentsForGroups,
  getParticipants,
} from "../../../../helpingFunctions/DataManipulation";

import CheckBox from "../../../CheckBox/CheckBox";
import CheckBoxLogic from "../../../CheckBox/CheckBoxLogic";
import TableNumber from "./components/TableNumber/TableNumber";
import SpecialRequestsList from "./components/SpecialRequestsList/SpecialRequestsList";

import arrival_styles from "./ArrivalsDayReservationRow.module.css";

const ArrivalDayReservationRowLogic = () => {
  const {
    setManipulatedReservations,
    reservations,
    setReservations,
    requestObject,
    selectedReservation,
    manipulatedReservations,
  } = useContext(ReservationContext);
  const {
    setAllSelectedCheck,
    allSelectedCheck,
    selectedItem,
    setSelectedItem,
  } = useContext(HelperContext);
  const { checkHandler } = CheckBoxLogic();
  const timeoutInterval = useRef([]);

  const handleReservationUpdate = (res, tableNum) => {
    try {
      const manipulatedResIndex = manipulatedReservations.findIndex(
        ({ id }) => id === res.id
      );

      if (manipulatedResIndex !== -1) {
        const clonedReservations = JSON.parse(
          JSON.stringify(manipulatedReservations)
        );
        const changeRes = clonedReservations[manipulatedResIndex];
        if (changeRes.isCreator) {
          const group = getParticipants(manipulatedReservations, changeRes);
          for (let index = 0; index < group.length; index++) {
            const participant = group[index];
            if (!participant.isCreator) {
              const participantIndex = manipulatedReservations.findIndex(
                ({ id }) => id === participant.id
              );
              clonedReservations[participantIndex].TableNumber = tableNum;
            }
          }
        }

        clonedReservations[manipulatedResIndex].TableNumber = tableNum;

        setManipulatedReservations([...clonedReservations]);
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in handleReservationUpdate func in ArrivalDayReservationRowLogic file ${
          error.message
        }`
      );
    }
  };

  const isCheckedAdd = (selectedIds, currentAttendStatus) => {
    try {
      setSelectedItem(null);

      let clonedReservations = JSON.parse(
        JSON.stringify(manipulatedReservations)
      );

      const recordsLn = clonedReservations.length;
      for (let index = 0; index < recordsLn; index++) {
        const item = clonedReservations[index];
        if (selectedIds.includes(item.id)) {
          item.HasAttendant = !currentAttendStatus;
        }
      }

      setManipulatedReservations([...clonedReservations]);
      const isInList = allSelectedCheck.some(({ id }) =>
        selectedIds.includes(id)
      );

      if (!isInList) {
        selectedIds.map((id) => {
          setAllSelectedCheck((prevState) => [
            ...prevState,
            {
              id: id,
              hasAttendant: !currentAttendStatus,
            },
          ]);
        });
      } else {
        setAllSelectedCheck((prevState) =>
          prevState.filter((prevItem) => !selectedIds.includes(prevItem.id))
        );
      }

      selectedIds.map((id) => {
        setSelectedItem(id);
        removeFromFilter(id, !currentAttendStatus, clonedReservations);
      });
    } catch (error) {
      console.error(
        `${new Date()} Error in isCheckedAdd func inside ArrivalDayReservationRowLogic file ${
          error.message
        }`
      );
    }
  };

  const removeFromFilter = (selectedId, status, clonedReservations) => {
    try {
      const resChecked = clonedReservations.find(
        ({ id, HasAttendant }) => id === selectedId && HasAttendant === status
      );

      if (resChecked) {
        if (resChecked.GroupId) {
          const allParticipants = clonedReservations.filter(
            ({ GroupId, Type, HasAttendant }) =>
              resChecked.GroupId === GroupId &&
              resChecked.Type === Type &&
              HasAttendant === status
          );
          const sameGroupParticipant = clonedReservations.filter(
            ({ GroupId, Type }) =>
              GroupId === resChecked.GroupId && Type === resChecked.Type
          );

          if (allParticipants.length === sameGroupParticipant.length) {
            const groupTimeOut = setTimeout(() => {
              allParticipants.map((checked) => {
                setManipulatedReservations((prevState) => {
                  const itemIndex = prevState.findIndex(
                    ({ id }) => id === checked.id
                  );
                  if (itemIndex >= 0) {
                    return prevState.filter(({ id }) => id !== checked.id);
                  } else {
                    return prevState;
                  }
                });
              });
            }, 2000);

            timeoutInterval.current.push(groupTimeOut);
          }
        } else {
          const singleTimeout = setTimeout(() => {
            setManipulatedReservations((prevState) => {
              const itemIndex = prevState.findIndex(
                ({ id }) => id === resChecked.id
              );
              if (itemIndex >= 0) {
                return prevState.filter(({ id }) => id !== resChecked.id);
              } else {
                return prevState;
              }
            });
          }, 2000);

          timeoutInterval.current.push(singleTimeout);
        }
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in removeFromFilter func in ArrivalDayReservationRowLogic file ${
          error.message
        }`
      );
    }
  };

  const checkAllGroupParticipants = async (participants, checkStatus) => {
    try {
      const checkParticipants = participants.filter(
        ({ HasAttendant }) => HasAttendant === checkStatus
      );

      const checkLn = checkParticipants.length;

      for (let index = 0; index < checkLn; index++) {
        const participant = checkParticipants[index];
        await checkHandler(participant, () => {}, checkStatus, "Dining");
      }

      const checkIds = checkParticipants.map(({ id }) => id);
      isCheckedAdd(checkIds, checkStatus);
    } catch (error) {
      console.error(
        `${new Date()} Error in checkAllGroupParticipants func in ArrivalDayReservationRowLogic file ${
          error.message
        }`
      );
    }
  };

  const constructText = (colHeaderTxt, colDbLabel, reservation, isChecked) => {
    let displayContent = null;
    const value = reservation[colDbLabel];
    const isSelected = selectedReservation.id === reservation.id;

    switch (colHeaderTxt) {
      case "Status": {
        displayContent = isChecked ? "Arrived" : "Not arrived";
        break;
      }
      case "Check In": {
        if (reservation.isCreator && !isSelected) {
          displayContent = <></>;
          break;
        }

        displayContent = (
          <CheckBox
            parentFunction={isCheckedAdd.bind(
              this,
              [reservation.id],
              reservation.HasAttendant
            )}
            hasAttendant={isChecked}
            selectedReservation={reservation}
          />
        );
        break;
      }
      case "Guest details": {
        const details = reservation.Comments;
        const isCreator = reservation.isCreator;
        let Img = null;
        if (details && !isCreator) {
          Img = (
            <img
              className={arrival_styles.Reservation_row_detailsIcon}
              src={"/img/icons/functionIcon/info.svg"}
              alt=""
            />
          );
        } else if (isCreator) {
          Img = (
            <img
              className={arrival_styles.Reservation_row_groupExpandIcon}
              src={"/img/icons/functionIcon/arrow.svg"}
              alt=""
            />
          );
        }

        displayContent = (
          <div
            className={arrival_styles.Reservation_row_firstCell}
            style={{
              cursor:
                reservation.GroupId && !reservation.isCreator
                  ? "default"
                  : "cursor",
            }}
          >
            <div className={arrival_styles.Reservation_row_visible}>
              {Img}
              {value}{" "}
              {reservation.GroupId ? ` (${reservation.CRSNumber})` : null}
            </div>
            <div className={arrival_styles.Reservation_row_details}>
              <SpecialRequestsList comments={reservation.Comments} />
            </div>
          </div>
        );
        break;
      }
      case "SR": {
        const { otherComments } = constuctCommentsForGroups(
          reservation.Comments
        );
        const hasSpecialRequests = otherComments.length > 0;
        displayContent = <p>{hasSpecialRequests ? "Yes" : "No"}</p>;
        break;
      }
      case "Time": {
        displayContent = value?.substring(0, value?.lastIndexOf(":"));
        break;
      }
      case "Activity": {
        displayContent = `${value} at ${reservation.ActivityTitle}`;
        break;
      }
      case "Type": {
        displayContent = `${value} at ${reservation.ActivityTitle}`;
        break;
      }
      case "Table #": {
        displayContent = (
          <TableNumber
            updateList={handleReservationUpdate}
            reservation={reservation}
          />
        );
        break;
      }
      case "PAX": {
        if (reservation.isCreator && !isSelected) {
          displayContent = `Total: ${
            reservation.totalGroupQtn
              ? reservation.totalGroupQtn
              : reservation.Quantity
          }`;
        } else {
          displayContent = value;
        }
        break;
      }

      default: {
        displayContent = value;
      }
    }

    return displayContent;
  };

  const checkIfSeparatedFromGroup = (groupId) =>
    reservations.some(
      ({ GroupId, isCreator }) => GroupId === groupId && isCreator
    );

  useEffect(() => {
    return () => {
      timeoutInterval.current.map((intervalID) => {
        clearTimeout(intervalID);
      });
    };
  }, []);

  return {
    constructText,
    checkAllGroupParticipants,
    checkIfSeparatedFromGroup,
  };
};

export default ArrivalDayReservationRowLogic;
