import React from "react";

import styles from "./Loader.module.css";

const Loader = () => {
  return (
    <div className={styles.Loader}>
      <img src={"/img/icons/ui/loading_.svg"} className={styles.LoaderIcon} />
    </div>
  );
};

export default Loader;
