import { useEffect, useState } from "react";

import axiosInstance from "../../url/axiosInstance";
import FilterSectionLogic from "../ReservationsPage/components/FilterSection/FilterSectionLogic";

const HomeLogic = () => {
  const instance = axiosInstance();
  const [hotels, setHotels] = useState([]);
  const { manageSelection } = FilterSectionLogic();

  const getHotels = async () => {
    try {
      const { data } = await instance.get("/hotels", {});
      const sortedHotels = data.sort((a, b) =>
        a.Title.toLowerCase().localeCompare(b.Title.toLowerCase())
      );

      setHotels(sortedHotels);
    } catch (error) {
      console.log(
        `${new Date()} Error in getHotels func in ReservationsPageLogic.js file ${error}`
      );
      localStorage.clear();
    }
  };

  const handleHotelSelection = (data) => {
    localStorage.setItem("hotel", JSON.stringify(data));
    manageSelection(
      data.id,
      {
        Title: data.Title,
        strapiHotelId: data.strapiHotelId,
        maestroHotelId: data.maestroHotelId,
        hasEnts: data.hasEnts,
      },
      "hotel"
    );
  };

  const autoSelectHotel = () => {
    const hotel = localStorage.getItem("hotel");

    if (hotel && hotel !== "null" && hotel !== "undefined") return;

    const userString = localStorage.getItem("userInfo");
    let hotelName;
    if (userString) {
      const { physicalDeliveryOfficeName } = JSON.parse(userString);

      hotelName = physicalDeliveryOfficeName;

      const theHotel = hotels.find(({ Title }) => Title === hotelName);

      if (!theHotel) return;
      const formatedHotelObj = {
        Title: theHotel?.Title,
        strapiHotelId: theHotel?.id,
        maestroHotelId: theHotel?.HotelId,
        hasEnts: theHotel.TwoLetterCode.length > 0,
      };
      localStorage.setItem("hotel", JSON.stringify(formatedHotelObj));
      manageSelection(theHotel?.id, formatedHotelObj, "hotel");
    }
  };

  const addHeaderToAxiosInstance = () => {
    const instanceHeaders = instance.defaults.headers;
    instance.defaults.headers = {
      ...instanceHeaders,
    };
  };

  useEffect(() => {
    addHeaderToAxiosInstance();
    getHotels();
  }, []);

  useEffect(() => {
    if (hotels.length > 0) autoSelectHotel();
  }, [hotels]);

  return { hotels, handleHotelSelection };
};

export default HomeLogic;
