import { useState } from "react";
import moment from "moment";
import { findIsValidTime } from "../../helpingFunctions/utilities";
import FilterSectionLogic from "../../views/ReservationsPage/components/FilterSection/FilterSectionLogic"

const TimeUtilPickerLogic = (
  setRequestObject,
  activeFiltersHandler,
  dateType,
  format,
  limit
) => {
  const { manageSelection } = FilterSectionLogic()
  const [isActive, setIsActive] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const isValidDate = (d) => d instanceof Date && !isNaN(d);

  const clearSelections = () => {
    activeFiltersHandler({
      reqType: dateType,
      filterType: null,
    });
    setRequestObject((prevState) => {
      return {
        ...prevState,
        [dateType]: null,
      };
    });
  };

  const handleChange = (value) => {
    setIsValid(true);
    if (!isValidDate(value)) {
      clearSelections();
      setIsValid(false);
      return;
    }

    const formatedValue = moment(value).format(format);
    if (dateType === "arrival_date") {
      const extractedYear = formatedValue.substring(
        0,
        formatedValue.indexOf("-")
      );

      const isValid = findIsValidTime(
        formatedValue,
        "TimeUtilPickerLogic",
        limit
      );
      const isLargerNum = extractedYear.toString().length > 4;

      if (isLargerNum || !isValid) {
        clearSelections();
        setIsValid(false);
        return;
      }
    }

    manageSelection(
      formatedValue,
      {
        dbValid: formatedValue,
        muiValid: value,
        presentation: formatedValue,
      },
      "arrival_date"
    );
  };

  return { handleChange, isActive, isValid, setIsActive };
};

export default TimeUtilPickerLogic;