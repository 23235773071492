import React, { useEffect, useState } from "react";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./AutoAllocationPreviewTable.module.css";
import {
  editPencil,
  xSign,
  checkCircle,
  warningSign,
} from "../../assets/SVGIcons";

const AutoAllocationPreviewTable = ({
  data,
  totalToAllocate,
  totalAutoAllocationsSucceeded,
  setPreviewEditObj,
  isProcessed,
}) => {
  const { height } = useWindowWidth();
  const [tableHeight, setTableHeight] = useState(0);

  const getResultIcon = (col2, col3) => {
    let finalStatus = "";
    if (!col2.status && !col3.status) {
      return;
    }

    if (!col2.status) {
      finalStatus = col3.status;
    } else if (!col3.status) {
      finalStatus = col2.status;
    } else {
      finalStatus =
        col2.status.includes("success") && col3.status.includes("success")
          ? "success"
          : col2.status.includes("failed") && col3.status.includes("failed")
          ? "failed"
          : "warning";
    }

    return finalStatus.includes("success")
      ? checkCircle
      : finalStatus.includes("failed")
      ? xSign
      : warningSign;
  };


  useEffect(() => {
    const isLandScape = window.outerWidth > height;
    const perc = isLandScape ? "calc(63vh - 101px)" : "calc(70vh - 101px)";
    setTableHeight(perc);
  }, [height]);

  return (
    <div
      style={{
        marginTop: "20px",
        width: "100%",
        padding: "0 50px",
        overflow: "auto",
        height: `${tableHeight}`,
      }}
    >
      <table>
        <tr>
          <th
            style={{
              width: isProcessed ? "20%" : "26%",
              padding: "10px",
              textAlign: "left",
            }}
          >
            Guest
          </th>
          <th
            style={{
              width: isProcessed ? "25%" : "37%",
              padding: "10px",
              textAlign: "left",
            }}
          >
            Breakfast Auto-allocation
          </th>
          <th
            style={{
              width: isProcessed ? "25%" : "37%",
              padding: "10px",
              textAlign: "left",
            }}
          >
            Dinner Auto-allocation
          </th>
          {isProcessed ? (
            <>
              <th style={{ width: "5%", padding: "10px", textAlign: "left" }}>
                Status
              </th>
              <th style={{ width: "25%", padding: "10px", textAlign: "left" }}>
                Comment
              </th>
            </>
          ) : null}
        </tr>
        {Object.entries(data).map(([key, value], index) => {
          return (
            <>
              <td
                key={key}
                rowSpan={value.length + 1}
                style={{ padding: "10px", verticalAlign: "top" }}
              >
                {key}
              </td>
              {value.map(({ col2, col3 }, index) => {
                return (
                  <tr>
                    <td
                      key={col2.txt + "_" + key}
                      style={{
                        padding: "10px",
                        height: "45px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color:
                            isProcessed &&
                            !["success", "retry-success"].includes(col2.status)
                              ? "red"
                              : "black",
                        }}
                      >
                        {col2.txt}

                        {col2.txt !== "-" &&
                        !["success", "retry-success"].includes(col2.status) ? (
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "5px",
                            }}
                            onClick={() =>
                              setPreviewEditObj({
                                key,
                                source: "col2",
                                col: col2,
                              })
                            }
                          >
                            {editPencil}
                          </div>
                        ) : null}
                      </div>
                    </td>
                    <td
                      key={col3.txt + "_" + key}
                      style={{
                        padding: "10px",
                        height: "45px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color:
                            isProcessed &&
                            !["success", "retry-success"].includes(col3.status)
                              ? "red"
                              : "black",
                        }}
                      >
                        {col3.txt}
                        {col3.txt !== "-" &&
                        !["success", "retry-success"].includes(col3.status) ? (
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "5px",
                            }}
                            onClick={() =>
                              setPreviewEditObj({
                                key,
                                source: "col3",
                                col: col3,
                              })
                            }
                          >
                            {editPencil}
                          </div>
                        ) : null}
                      </div>
                    </td>
                    {isProcessed ? (
                      <>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              height: "24px",
                            }}
                          >
                            {getResultIcon(col2, col3)}
                          </div>
                        </td>
                        <td>
                          <div style={{ padding: "10px" }}>
                            {col2.txt !== "-" ? (
                              <p>{`Breakfast: ${col2.hasOwnProperty("comment") ? col2.comment : "Record updated. Retry to confirm changes."}`}</p>
                            ) : null}
                            {col3.txt !== "-" ? (
                              <p>{`Dinner: ${col3.hasOwnProperty("comment") ? col3.comment : "Record updated. Retry to confirm changes."}`}</p>
                            ) : null}
                          </div>
                        </td>
                      </>
                    ) : null}
                  </tr>
                );
              })}
            </>
          );
        })}
        <tr>
          <th
            colSpan={isProcessed ? 4 : 2}
            style={{ padding: "10px", textAlign: "right" }}
          >
            {isProcessed
              ? "Total Bookings created"
              : "Total Bookings to be created"}
          </th>
          <th style={{ padding: "10px" }}>{totalAutoAllocationsSucceeded ? totalAutoAllocationsSucceeded : totalToAllocate}</th>
        </tr>
      </table>
    </div>
  );
};

export default AutoAllocationPreviewTable;
