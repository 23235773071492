import React, { useContext } from "react";
import { ReservationContext } from "../../../../context/ReservationContext";
import Button from "../../../Button/Button";
import SpecialRequestsLogic from "./SpecialRequestsLogic";
import SpecialRequestsList from "../../../ReservationTable/components/ArrivalsDayReservationRow/components/SpecialRequestsList/SpecialRequestsList";

import styles from "./SpecialRequests.module.css";

const SpecialRequests = () => {
  const { selectedReservation } = useContext(ReservationContext);
  const {
    addedSpecialRequest,
    isLoading,
    updateSpecialReq,
    handleSpecialRequestSave,
  } = SpecialRequestsLogic();

  let ExistingSpecialRequests = null;
  if (selectedReservation.Comments) {
    ExistingSpecialRequests = (
      <>
        <label className={styles.SpecialRequests__label}>
          Existing Special Request:
        </label>
        <span className={styles.SpecialRequests__comments}>
          <SpecialRequestsList comments={selectedReservation.Comments} />
        </span>
      </>
    );
  }

  return (
    <div className={styles.SpecialRequests}>
      <div className={styles.SpecialRequests__stored}>
        {ExistingSpecialRequests}
      </div>
      <div className={styles.SpecialRequests__content}>
        <textarea
          className={styles.SpecialRequests__input}
          value={addedSpecialRequest}
          onChange={updateSpecialReq}
          placeholder={"Special Requests..."}
        ></textarea>
        <Button
          txt={"Update Request"}
          handleClick={handleSpecialRequestSave}
          type={1}
          deactivate={!addedSpecialRequest || isLoading}
          new_style={{
            marginLeft: "30px",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        />
      </div>
    </div>
  );
};

export default SpecialRequests;
