import React, { useContext } from "react";

import Loader from "../Loader/Loader";
import ItineraryDropDowns from "./components/ItineraryDropDowns/ItineraryDropDown";
import ItineraryLogic from "./ItineraryLogic";
import SelectedRowCard from "../SelectedRowCard/SelectedRowCard";
import { ReservationContext } from "../../context/ReservationContext";
import AutoAllocation from "../AutoAllocation/AutoAllocation";
import { formatDate } from "../../helpingFunctions/utilities";

import styles from "./Itinerary.module.css";
import SpecialRequests from "./components/SpecialRequests/SpecialRequests";

const dropDowns = [
  {
    type: "Breakfast",
  },
  {
    type: "Dinner",
  },
];
const Itinerary = ({
  id,
  hotelId,
  reservationNumber,
  lastName,
  quantity,
  status,
}) => {
  const { itineraryData, availability, facilityData, isLoading } =
    ItineraryLogic(id, hotelId, quantity, reservationNumber, lastName);
  const { currentPage } = useContext(ReservationContext);
  const isInReservationsPage = currentPage === "reservations";

  if (isLoading) {
    return (
      <SelectedRowCard>
        <Loader
          icon={"loading.svg"}
          isLoading={isLoading}
          new_style={{
            position: "relative",
            height: "100%",
            backgroundColor: "transparent",
            marginTop: "40px",
          }}
          new_style_icon={{
            width: "90px",
            height: "90px",
          }}
        />
      </SelectedRowCard>
    );
  }

  return (
    <SelectedRowCard new_style={{ flexDirection: "column" }}>
      <div className={styles.Itinerary__selections}>
        <ul className={styles.Itinerary__dates}>
          {itineraryData.ItineraryData?.map(({ Date }) => (
            <li className={styles.Itinerary__date_item}>{formatDate(Date)}</li>
          ))}
        </ul>
        <div className={styles.Itinerary__selectBoxes}>
          {dropDowns.map(({ type }, index) => (
            <ItineraryDropDowns
              key={type}
              id={id}
              label={type}
              icon={type.toLocaleLowerCase()}
              itineraryData={itineraryData}
              availability={availability}
              facilityData={facilityData}
              reservationNumber={reservationNumber}
              lastName={lastName}
              quantity={quantity}
              isOdd={index % 2 !== 0}
            />
          ))}
        </div>
        <div className={styles.Itinerary_autoAllocate}>
          {isInReservationsPage && !status ? (
            <AutoAllocation autoAllocateAll={false} />
          ) : null}
        </div>
      </div>
      <SpecialRequests />
    </SelectedRowCard>
  );
};

export default Itinerary;
