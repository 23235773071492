import React from "react";

import AddedFilterLogic from "./AddedFilterLogic";
import { formatDate } from "../../helpingFunctions/utilities";

import styles from "./AddedFilter.module.css";

const AddedFilter = ({
  txt,
  filterReqType,
  setRequestObject,
  cleanFilterArray,
}) => {
  const { removeFilter } = AddedFilterLogic(
    filterReqType,
    setRequestObject,
    cleanFilterArray
  );

  let formatedText = txt;
  if (filterReqType === "arrival_date") {
    formatedText = formatDate(txt);
  }

  return (
    <div className={styles.AddedFilter} onClick={removeFilter}>
      <span className={styles.AddedFilter_cancel}>X</span> {formatedText}
    </div>
  );
};

export default AddedFilter;
