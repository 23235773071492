import React, { useEffect } from "react";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import DatePicker from "@mui/lab/DatePicker";
import TimeUtilPickerLogic from "./TimeUtilPickerLogic";
import SelectCard from "../SelectCard/SelectCard";

import style from "../DropdownBox/DropDownBox.module.css";

const TimeUtilPicker = ({
  label,
  setRequestObject,
  dateType,
  requestObject,
  type,
  format,
  autoActivate,
  limit,
  activeFiltersHandler = () => {},
  setShowDatePicker = () => {},
  wrapperStyle = {},
  muiProps = {},
  inputStyle = {},
}) => {
  const { isActive, isValid, handleChange, setIsActive } = TimeUtilPickerLogic(
    setRequestObject,
    activeFiltersHandler,
    dateType,
    format,
    limit
  );

  useEffect(() => {
    if (autoActivate) setIsActive(autoActivate);
  }, [autoActivate]);

  const Picker = type === "date" ? DatePicker : TimePicker;

  return (
    <div>
      <p className={style.DropDownBox__label}>{label}</p>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Picker
          inputFormat={"dd/MM/yyyy"}
          open={isActive}
          value={
            requestObject[dateType] ? requestObject[dateType].muiValid : null
          }
          onChange={handleChange}
          onClose={() => {
            setIsActive(false);
            setShowDatePicker(false);
          }}
          DialogProps={{
            PaperProps: {
              sx: { "& *:focus": { outline: "none" } },
            },
          }}
          renderInput={(params) => {
            return (
              <SelectCard
                new_style={{
                  cursor: "pointer",
                  paddingLeft: "0",
                  paddingRight: "0",
                  borderColor: !isValid ? "rgb(172, 27, 27)" : "#525e8b",
                  width: "200px",
                  ...wrapperStyle,
                }}
                onClick={setIsActive.bind(this, !isActive)}
              >
                <TextField
                  {...params}
                  sx={{
                    backgroundImage: `url(/img/icons/${
                      !isActive ? "drop-down-arrow.svg" : "arrow-up.svg"
                    })`,
                    border: "none",
                    outline: "none",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "25px",
                    width: "100%",
                    backgroundPositionX: "95%",
                    backgroundPositionY: "50%",
                    ...inputStyle,
                  }}
                  error={false}
                />
              </SelectCard>
            );
          }}
          {...muiProps}
        />
      </LocalizationProvider>
    </div>
  );
};

export default TimeUtilPicker;
