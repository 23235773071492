import React from "react";
import ItinerarySelectButtonLogic from "../ItinerarySelectButtonLogic";

import styles from "../ItinerarySelectButton.module.css";

const TimeCard = ({
  new_style,
  value,
  onClick,
  dateSelected,
  typeSelected,
  availability,
  selResQuantity,
  isSkiped = false,
}) => {
  const { currentDaySelections } = ItinerarySelectButtonLogic(
    dateSelected,
    typeSelected
  );
  const isSelected =
    currentDaySelections?.Date?.split("T")[1]?.substr(0, 5) === value;

  let isAvailable = true;
  let AvailableSlots = null;

  if (availability && currentDaySelections?.id) {
    isAvailable = availability?.some(({ FacilityGUID, Availability }) =>
      FacilityGUID === currentDaySelections.id && selResQuantity <= Availability
        ? Availability
        : 0
    );

    const foundAvail = availability.find(
      ({ FacilityGUID }) => FacilityGUID === currentDaySelections?.id
    );
    AvailableSlots = (
      <span className={styles.TimeCard__availability}>
        {" "}
        [{foundAvail ? foundAvail.Availability : "0"}]
      </span>
    );
  } else {
    isAvailable = !isSkiped;
  }

  return (
    <div
      className={`${styles.ItinerarySelectButton} ${
        isSelected ? styles.ItinerarySelectButtonHighlited : ""
      } ${
        !isAvailable && value !== currentDaySelections.timeBooked
          ? styles.ItinerarySelectButtonNotAvailable
          : ""
      }`}
      style={{ ...new_style }}
      onClick={onClick}
    >
      {value}
      {AvailableSlots}
    </div>
  );
};

export default TimeCard;
