import axiosInstance from "../../url/axiosInstance";

const CheckBoxLogic = () => {
  const instance = axiosInstance();
  const checkHandler = async (
    selectedReservation,
    parentFunction,
    checked,
    type
  ) => {
    try {
      let endpoint = "";
      if (["Breakfast", "Dinner", "Lunch", "Dining"].includes(type)) {
        endpoint = "dinning";
      } else if (type === "Venue") {
        endpoint = "entertainment";
      } else {
        endpoint = "activity";
      }

      await instance.put(`${endpoint}/arrivals/${selectedReservation.id}`, {
        changedObject: {
          ...selectedReservation,
          HasAttendant: !checked,
        },
      });

      parentFunction();
    } catch (error) {}
  };

  return { checkHandler };
};

export default CheckBoxLogic;
