import { redirectTo } from "../../helpingFunctions/linkRedirect";

const LoginLinkPageLogic = () => {
  const BE_LINK = process.env.REACT_APP_BACK_ENDPOINT.split("/v1/27abb061");
  const loginHandler = () => {
    localStorage.clear();
    redirectTo(`${BE_LINK[0]}/auth/login`, "_self");
  };

  return { loginHandler };
};

export default LoginLinkPageLogic;
