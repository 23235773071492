import {
  IN_GROUP_DEFAULT,
  IN_GROUP_DEFAULT_NEW,
  IN_GROUP_DEFAULT_NEW_CAPITAL,
  IN_GROUP_DEFAULT_TYPO,
  OUT_OF_GROUP_DEFAULT,
  OUT_OF_GROUP_DEFAULT_NEW,
} from "../constants/constants";

const pms_reservation_attributes = [
  "CRSNumber",
  "FirstName",
  "LastName",
  "ReservationNumber",
  "RoomNumber",
];

const findFacilityTitleById = (facilityData, facilityId) => {
  try {
    const { Title } = facilityData.find(({ id }) => id === facilityId);

    return Title;
  } catch (error) {
    console.log(
      `${new Date()} Error in findFacilityTitleById func in DataManipulation.js file ${
        error.message
      }`
    );
  }
};

const getParticipants = (allReservations, groupLeader) => {
  const participants = allReservations.filter(
    ({ GroupId, Type }) =>
      GroupId && GroupId === groupLeader.GroupId && groupLeader.Type === Type
  );

  return participants;
};

const constuctCommentsForGroups = (Comments) => {
  try {
    const topComments = [];
    const otherComments = [];

    if (!Comments) {
      return {
        topComments,
        otherComments,
      };
    }

    const commentsList = Comments.split("\n").filter((c) => c);

    for (let index = 0; index < commentsList.length; index++) {
      const comment = commentsList[index];
      const formatedComment = comment.substring(
        comment.indexOf(" ") + 1,
        comment.length
      );
      if (!formatedComment) {
        continue;
      }

      if (
        formatedComment.includes(IN_GROUP_DEFAULT) ||
        formatedComment.includes(OUT_OF_GROUP_DEFAULT) ||
        formatedComment.includes(IN_GROUP_DEFAULT_TYPO)
      ) {
        const formatedCommentModified = formatedComment.replace(
          "group",
          "party"
        );
        topComments.push(formatedCommentModified);
      } else if (formatedComment.includes(IN_GROUP_DEFAULT_NEW_CAPITAL)) {
        const formatedCommentModified = formatedComment.replace(
          "Party",
          "party"
        );
        topComments.push(formatedCommentModified);
      } else if (
        formatedComment.includes(IN_GROUP_DEFAULT_NEW) ||
        formatedComment.includes(OUT_OF_GROUP_DEFAULT_NEW)
      ) {
        topComments.push(formatedComment);
      } else {
        otherComments.push(formatedComment);
      }
    }

    return {
      topComments,
      otherComments,
      formatedComments: [...topComments, ...otherComments],
    };
  } catch (error) {
    console.error(
      `${new Date()} Error in constuctCommentsForGroups func inside DataManipulation.js file ${
        error.message
      }`
    );
  }
};
const checkIfIsReserevationAttribute = (attribute) =>
  pms_reservation_attributes.includes(attribute);

export {
  findFacilityTitleById,
  checkIfIsReserevationAttribute,
  getParticipants,
  constuctCommentsForGroups,
};
