import React from "react";
import PageWall from "../PageWall/PageWall";

import styles from "./Loader.module.css";

const Loader = ({
  isLoading,
  icon,
  new_style,
  new_style_icon,
  additionalTxt,
}) => {
  return (
    <PageWall new_styles={{ ...new_style, flexDirection: "column" }} source={"Loader"}>
      <img
        className={`${styles.Loader_img} ${
          isLoading ? styles.Loader_img_spin : ""
        }`}
        style={new_style_icon}
        src={`img/icons/${icon}`}
        alt={"no img"}
      />
      <p className={styles.Loader_txt}>{additionalTxt}</p>
    </PageWall>
  );
};

export default Loader;
