import React, { useContext, useState, useEffect } from "react";

import { addDaysToDate } from "../../../../../../helpingFunctions/utilities";
import { filterBy } from "../../../../../../assets/StaticObject";
import { ReservationContext } from "../../../../../../context/ReservationContext";
import { refreshIcon } from "../../../../../../assets/SVGIcons";
import DropDownBox from "../../../../../../components/DropdownBox/DropDownBox";
import TimeUtilPicker from "../../../../../../components/TimeUtilPicker/TimeUtilPicker";
import AutoAllocation from "../../../../../../components/AutoAllocation/AutoAllocation";
import ToggleButton from "../../../../../../components/UI/ToggleButton/ToggleButton";
import { GROUP_LABELS } from "../../../../../../constants/constants";

const ReservationsFilter = ({
  requestObject,
  setRequestObject,
  manageSelection,
  selectedState,
  activeFiltersHandler,
}) => {
  const [getCount, setGetCount] = useState(0);
  const { getNumberOfItems, refreshReservations } =
    useContext(ReservationContext);

  const getFilterResCounts = async () => {
    setGetCount(await getNumberOfItems());
  };

  const showAutoallocateButton =
    requestObject.arrival_date &&
    requestObject.status?.value &&
    requestObject.status?.value !== "booked" &&
    !requestObject.search?.searchValue &&
    getCount > 0;

  useEffect(() => {
    if (
      requestObject.arrival_date &&
      requestObject.status?.value &&
      requestObject.status?.value !== "booked" &&
      !requestObject.search?.searchValue
    ) {
      getFilterResCounts();
    } else {
      setGetCount(0);
    }
  }, [requestObject]);

  return (
    <>
      <TimeUtilPicker
        label={"Arrival Date"}
        dateType={"arrival_date"}
        setRequestObject={setRequestObject}
        activeFiltersHandler={activeFiltersHandler}
        requestObject={requestObject}
        manageSelection={manageSelection}
        type={"date"}
        format={"YYYY-MM-DD"}
        limit={14}
        muiProps={{
          minDate: new Date(),
          maxDate: addDaysToDate(14),
        }}
      />
      <ToggleButton
        action={(val) =>
          manageSelection(
            Math.random(),
            {
              value: val,
              presentation: val
                ? `Is In ${GROUP_LABELS.GroupRef.lowerCase}`
                : `Is Not In ${GROUP_LABELS.GroupRef.lowerCase}`,
            },
            "isPartOfGroup"
          )
        }
      />
      <DropDownBox
        selectedItem={selectedState(
          filterBy,
          requestObject?.status?.id,
          "status"
        )}
        label={"Filter by"}
        placeHolder={"Select a status"}
      >
        {filterBy?.map(({ id, status, hasBooked }) => (
          <li
            onClick={manageSelection.bind(
              this,
              id,
              { value: hasBooked, presentation: status },
              "status"
            )}
            key={id}
          >
            {status}
          </li>
        ))}
      </DropDownBox>
      {showAutoallocateButton ? (
        <div
          style={{ display: "flex", alignItems: "center", padding: "0 15px" }}
        >
          <div
            style={{ paddingRight: "10px" }}
            onClick={() => refreshReservations()}
          >
            {refreshIcon}
          </div>
          <AutoAllocation autoAllocateAll={true} />
        </div>
      ) : null}
    </>
  );
};

export default ReservationsFilter;
