import React from "react";

import styles from "./PageHeadLine.module.css";

const PageHeadLine = ({ title }) => {
  const hotelString = localStorage.getItem("hotel");
  let hotelTitle;

  if (hotelString) {
    const hotel = JSON.parse(hotelString);
    hotelTitle = hotel.Title;
  }
  return (
    <div className={styles.PageHeadLine}>
      <h1 className={styles.PageHeadLine_title}>
        {title ? `${title} - ` : ""}
        <span className={styles.PageHeadLine__hotel_title}>{hotelTitle}</span>
      </h1>
    </div>
  );
};

export default PageHeadLine;
