import { useState, useEffect } from "react";

const AutoAllocationPreviewEditLogic = (
  venueTypeSm,
  selectedDateTime,
  marketKitchenFacilityCodes,
  facilityData,
  previewEditObj,
  availabilityLeftOvers,
  originalRecords,
  setOriginalRecords,
  setAvailabilityLeftOvers,
  setPreviewEditObj,
) => {
  const [selection, setSelection] = useState(null);
  const [restaurantType, setRestaurantType] = useState(null);
  const [isRestaurantAvailable, setIsRestaurantAvailable] = useState(true);

  const updateSelection = (key, val, FacilityCode) => {
    const copy = JSON.parse(JSON.stringify(selection));
    copy[key] = val;

    if (FacilityCode) {
        setRestaurantType(marketKitchenFacilityCodes.includes(FacilityCode) ? "primary" : "secondary");
        copy.FacilityCode = FacilityCode;
    }
    setSelection(copy);
  };

  const hasResAvail = () => {
    const restAvailObj = {};
    facilityData.forEach((row) => {
      const restPriority = marketKitchenFacilityCodes.includes(row.FacilityCode)
        ? "primary"
        : "secondary";

      const availability = availabilityLeftOvers[venueTypeSm][restPriority][
        selectedDateTime[0]
      ].filter(
        (availObj) =>
          availObj.FacilityCode === row.FacilityCode &&
          availObj.Quantity >= previewEditObj.col.details.FacilityQuantity
      );

      restAvailObj[row.FacilityCode] = availability.length === 0;
    });

    setIsRestaurantAvailable(restAvailObj);
  };

  const updateAutoAllocationSelection = () => {
    const crsNum = previewEditObj.col.details.CRSNumber;
    const bookedRestPriority = marketKitchenFacilityCodes.includes(previewEditObj.col.details.FacilityCode)
    ? "primary"
    : "secondary";
    
    const availabilityCopy = JSON.parse(JSON.stringify(availabilityLeftOvers));
    // Restore the avail quantity to the auto allocated hour
    const bookedAvailIndex = availabilityCopy[venueTypeSm][bookedRestPriority][selectedDateTime[0]].findIndex((row) => row.time === selectedDateTime[1]);
    availabilityCopy[venueTypeSm][bookedRestPriority][selectedDateTime[0]][bookedAvailIndex].Quantity += previewEditObj.col.details.FacilityQuantity;
    
    // Deduct the avail quantity from the editted hour
    const updatedAvailIndex = availabilityCopy[venueTypeSm][restaurantType][selectedDateTime[0]].findIndex((row) => row.time === selection.time);
    availabilityCopy[venueTypeSm][restaurantType][selectedDateTime[0]][updatedAvailIndex].Quantity -= previewEditObj.col.details.FacilityQuantity;
    setAvailabilityLeftOvers(availabilityCopy);

    // Update the selection in the original data array
    const originalDataIndex = originalRecords.findIndex((row) => row.CRSNumber === crsNum && previewEditObj.col.details.FacilityDate === row.FacilityDate);
    const originalDataCopy = JSON.parse(JSON.stringify(originalRecords));
    originalDataCopy[originalDataIndex].FacilityCode = selection.FacilityCode;
    originalDataCopy[originalDataIndex].FacilityDate = `${selectedDateTime[0]}T${selection.time}`;
    originalDataCopy[originalDataIndex].activityId = selection.restaurant;

    setOriginalRecords(originalDataCopy);
    setPreviewEditObj(null);
  };

  useEffect(() => {
    hasResAvail();
    setSelection({
      restaurant: previewEditObj.col.details.activityId,
      time: selectedDateTime[1],
      FacilityCode: previewEditObj.col.details.FacilityCode
    });
    setRestaurantType(
      marketKitchenFacilityCodes.includes(previewEditObj.col.details.FacilityCode)
        ? "primary"
        : "secondary"
    );
  }, []);

  return {
    selection,
    restaurantType,
    isRestaurantAvailable,
    updateSelection,
    updateAutoAllocationSelection,
  };
};

export default AutoAllocationPreviewEditLogic;
