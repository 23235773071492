class TableColName {
  constructor(
    txt,
    dbLabel,
    justifyContent,
    paddingLeft,
    colWidthDesktop,
    colWidthTablet,
    colMinWidth,
    isAscOrder = true
  ) {
    this.txt = txt;
    this.dbLabel = dbLabel;
    this.justifyContent = justifyContent;
    this.paddingLeft = paddingLeft;
    this.colWidthDesktop = colWidthDesktop;
    this.colWidthTablet = colWidthTablet;
    this.colMinWidth = colMinWidth;
    this.isAscOrder = isAscOrder;
  }
}

export default TableColName;
