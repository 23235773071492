import { useContext, useState } from "react";
import axiosInstance from "../../url/axiosInstance";
import { ReservationContext } from "../../context/ReservationContext";
import ItineraryLogic from "../Itinerary/ItineraryLogic";

const AutoAllocationPreviewBoxLogic = (
  originalRecords,
  autoAllocateAll,
  autoAllocateResult,
  setAutoAllocateResult,
  setIsAutoAllocateRetryLoading,
  setIsAutoAllocateLoading,
  setIsAutoAllocatePrepTriggered,
  setOriginalRecords,
  setAvailabilityLeftOvers,
  setAlertMsg,
) => {
  const instance = axiosInstance();
  const { requestObject, selectedReservation, getReservations } =
    useContext(ReservationContext);
  const { getItinerary, getAvailability } = ItineraryLogic(
    selectedReservation.id,
    selectedReservation.HotelId,
    selectedReservation.Adults,
    selectedReservation.ReservationNumber,
    selectedReservation.LastName,
    autoAllocateAll
  );

  const [previewEditObj, setPreviewEditObj] = useState(null);
  const [totalAutoAllocationsSucceeded, setTotalAutoAllocationsSucceeded] = useState(null);
  const [areAllSuccess, setAreAllSuccess] = useState(false);
  const [autoAllocateProgressId, setAutoAllocateProgressId] = useState(null);
  const [autoAllocateProgressPercentage, setAutoAllocateProgressPercentage] =
    useState("0.00%");

  const initiateAutoAllocation = async () => {
    setIsAutoAllocateLoading(true);
    const params = autoAllocateAll
      ? {
          autoAllocateAll,
          hotelId: requestObject.hotel.strapiHotelId,
          arrival_date: requestObject.arrival_date?.dbValid,
          filter: requestObject.status.value,
        }
      : {
          autoAllocateAll,
          hotelId: requestObject.hotel.strapiHotelId,
          arrival_date: selectedReservation.ArrivalDate.split("T")[0],
          reservationNumber: selectedReservation.ReservationNumber,
        };

    try {
      const { data } = await instance.post("/dinning/auto-allocate/process", {
        data: originalRecords,
        params,
      });

      setAutoAllocateProgressId(data);
      await fetchAutoAllocateStatus(data, originalRecords.length, false);
    } catch (error) {
      setIsAutoAllocateLoading(false);
      console.log(`Auto-allocation process failed ${error.message}`);
      setAlertMsg(`Something went wrong. Please try again later!`);
    }
  };

  const initiateAutoAllocationRetry = async () => {
    setAutoAllocateProgressPercentage("0.00%")
    setIsAutoAllocateRetryLoading(true);

    const params = autoAllocateAll
      ? {
          autoAllocateAll,
          hotelId: requestObject.hotel.strapiHotelId,
          arrival_date: requestObject.arrival_date?.dbValid,
          filter: requestObject.status.value,
        }
      : {
          autoAllocateAll,
          hotelId: requestObject.hotel.strapiHotelId,
          arrival_date: selectedReservation.ArrivalDate.split("T")[0],
          reservationNumber: selectedReservation.ReservationNumber,
        };

    params.progressId = autoAllocateProgressId;

    try {
      const failedRecords = autoAllocateResult.filter(
        (row) => !["success", "retry-success"].includes(row.Status)
      );

      const failedRecordsPrep = [];
      failedRecords.forEach((row) => {
        let failedRecord;
        failedRecord = originalRecords.find(
          (originalRecord) =>
            originalRecord.CRSNumber === row.CRSNumber &&
            originalRecord.FacilityDate === `${row.Date}T${row.Time}`
        );

        if (!failedRecord) {
          failedRecord = originalRecords.find(
            (originalRecord) =>
              originalRecord.CRSNumber === row.CRSNumber &&
              originalRecord.FacilityDate.startsWith(row.Date) &&
              originalRecord.type === row.Type
          );
        }

        failedRecord.AutoAllocateStatusRecordId = row.id;
        failedRecordsPrep.push(failedRecord);
      });

      const { data } = await instance.post(
        "/dinning/auto-allocate/retry-fails",
        {
          data: failedRecordsPrep,
          params,
        }
      );

      setAutoAllocateProgressId(data);
      await fetchAutoAllocateStatus(data, failedRecords.length, true);
    } catch (error) {
      setIsAutoAllocateRetryLoading(false);
      console.log(`Auto-allocation retry failed ${error.message}`);
      setAlertMsg(`Something went wrong. Please try again later!`);
    }
  };

  const fetchAutoAllocateStatus = async (id, total, isRetry) => {
    let inter = setInterval(async () => {
      try {
        const { data } = await instance.get(
          `/auto-allocate/progress/${id}&${isRetry}`
        );

        updateProgressBar(data.length, total);
        if (data.length === total) {
          setAutoAllocateResult(data);
          const succeedCount = data.filter((row) => row.Status.includes("success"));
          setAreAllSuccess(
            succeedCount.length ===
              data.length
          );
          setTotalAutoAllocationsSucceeded(succeedCount.length);
          clearInterval(inter);
          return;
        }
      } catch (e) {
        clearInterval(inter);
        return;
      }
    }, 2000);
  };

  const updateProgressBar = (processed, total) => {
    let percentage = (processed / total) * 100;
    if (percentage <= 100) {
      setAutoAllocateProgressPercentage(`${percentage.toFixed(2)}%`);
    } else {
      setAutoAllocateProgressPercentage(`100%`);
    }
  };

  const deleteRecords = async () => {
    await instance.get(
      `/auto-allocate/progress/delete/${autoAllocateProgressId}`
    );
  };

  const resetUponDismiss = async () => {
    if (autoAllocateProgressId) {
      await deleteRecords();
      await getReservations(requestObject);
      if (!autoAllocateAll) {
        await getItinerary();
        await getAvailability();
      }
    }
    setIsAutoAllocatePrepTriggered(null);
    setAutoAllocateResult(null);
    setOriginalRecords(null);
    setAvailabilityLeftOvers(null);
  };

  return {
    requestObject,
    previewEditObj,
    autoAllocateProgressId,
    setPreviewEditObj,
    initiateAutoAllocation,
    initiateAutoAllocationRetry,
    autoAllocateProgressPercentage,
    autoAllocateResult,
    resetUponDismiss,
    areAllSuccess,
    totalAutoAllocationsSucceeded,
  };
};

export default AutoAllocationPreviewBoxLogic;
