import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Header from "../components/Header/Header";
import { HelperProvider } from "../context/HelperContext";
import { ReservationProvider } from "../context/ReservationContext";
import CallbackPage from "../views/CallbackPage/CallbackPage";
import Home from "../views/Home/Home";
import LoginLinkPage from "../views/LoginLinkPage/LoginLinkPage";
import ReservationsPage from "../views/ReservationsPage/ReservationsPage";

const Index = () => {
  return (
    <Router>
      <ReservationProvider>
        <HelperProvider>
          <Header />
          <Routes>
            <Route exact path="/" element={<LoginLinkPage />}></Route>
            <Route exact path="/callback" element={<CallbackPage />}></Route>
            <Route path="/home" element={<Home />}>
              {" "}
            </Route>
            <Route path="/arrivals/*">
              <Route path=":type" element={<ReservationsPage />}>
              </Route>
            </Route>
            <Route
              exact
              path="/reservations"
              element={<ReservationsPage />}
            ></Route>
          </Routes>
        </HelperProvider>
      </ReservationProvider>
    </Router>
  );
};

export default Index;
