import React, { useContext } from "react";

import { ReservationContext } from "../../../../../../context/ReservationContext";
import { checkInOutStatus } from "../../../../../../helpingFunctions/utilities";
import { HelperContext } from "../../../../../../context/HelperContext";
import { sumNumbers } from "../../../../../../helpingFunctions/calculations";
import GroupDisplayLogic from "./GroupDisplayLogic";
import ArrivalDayReservationRowLogic from "../../ArrivalDayReservationRowLogic";
import Button from "../../../../../Button/Button";
import tableStyles from "../../../../ReservationTable.module.css";

import styles from "./GroupDisplay.module.css";

const GroupDisplay = ({
  reservation,
  tableCols,
  isInMediumScreen,
  isInSmallScreen,
  onClick,
}) => {
  const { requestObject } = useContext(ReservationContext);
  const { allSelectedCheck } = useContext(HelperContext);
  const { constructText, checkAllGroupParticipants } =
    ArrivalDayReservationRowLogic();
  const { groupParticipants } = GroupDisplayLogic(reservation);
  const totalParticipants = sumNumbers(groupParticipants, "Quantity");

  return (
    <>
      {groupParticipants.map((reservation) => (
        <ul
          className={styles.GroupDisplay}
          key={reservation.id}
          style={{
            color: checkInOutStatus(allSelectedCheck, reservation)
              ? "#525e8b"
              : "#000000",
          }}
        >
          {tableCols.map(
            ({
              txt,
              colWidthTablet,
              colWidthDesktop,
              dbLabel,
              justifyContent,
              paddingLeft,
            }) => (
              <li
                className={tableStyles.ReservationTable_cell}
                key={txt}
                style={{
                  width:
                    isInMediumScreen || isInSmallScreen
                      ? colWidthTablet
                      : colWidthDesktop,
                  justifyContent,
                  paddingLeft:
                    isInSmallScreen && paddingLeft !== "0px"
                      ? "10px"
                      : paddingLeft,
                  cursor: reservation.isCreator ? "pointer" : null,
                }}
                onClick={
                  dbLabel === "GuestName" && reservation.isCreator
                    ? onClick.bind(this, reservation)
                    : null
                }
              >
                {constructText(
                  txt,
                  dbLabel,
                  reservation,
                  checkInOutStatus(allSelectedCheck, reservation)
                )}
              </li>
            )
          )}
        </ul>
      ))}
      <div className={styles.GroupDisplay__checkAllBtn}>
        <div className={styles.GroupDisplay__totalNumber}>
          Total number of people: {totalParticipants}
        </div>
        <Button
          txt={
            requestObject.checked?.value
              ? "Check out full dining party"
              : "Check in full dining party"
          }
          handleClick={checkAllGroupParticipants.bind(
            this,
            groupParticipants,
            requestObject.checked?.value ? true : false
          )}
          type={1}
          new_style={{
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        />
      </div>
    </>
  );
};

export default GroupDisplay;
