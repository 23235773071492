import { useContext, useEffect, useState } from "react";
import { SelectionContext } from "../../context/SelectionContext";

const ItinerarySelectButtonLogic = (dateSelected, typeSelected) => {
  const { selectionsObjects, getSelection } = useContext(SelectionContext);
  const [currentDaySelections, setCurrentDaySelections] = useState({});

  const findCurrentSelection = () => {
    try {
      const currentDayItem = getSelection(dateSelected, typeSelected);

      setCurrentDaySelections(currentDayItem);
    } catch (error) {
      console.log(
        `${new Date()} Error in findCurrentSelection func in ItinerarySelectButtonLogic.js file ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    if (selectionsObjects.length > 0) {
      findCurrentSelection();
    }
  }, [selectionsObjects]);
  return { currentDaySelections };
};

export default ItinerarySelectButtonLogic;
