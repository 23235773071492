import { useEffect, useState } from "react";
import { checkIfBooked } from "../../../../helpingFunctions/bookingHelp";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import reservation_row_styles from "./ReservationRow.module.css";

const ReservationRowLogic = (reservation, selectedReservation) => {
  const { isInLowRange } = useWindowWidth();


  const contructText = (colHeaderTxt, colDbLabel) => {
    let displayContent = null;
    const bfst = selectedReservation.id === reservation.id ? selectedReservation.Breakfast : reservation.Breakfast;
    const dinner = selectedReservation.id === reservation.id ? selectedReservation.Dinner : reservation.Dinner;

    switch (colHeaderTxt) {
      case "Status": {
        displayContent = (
          <div className={reservation_row_styles.ReservationRow_status}>
            <img
              className={reservation_row_styles.ReservationTable__img}
              src={`/img/icons/${
                checkIfBooked(bfst, dinner).icon
              }`}
              alt="no img"
            />
            {!isInLowRange
              ? checkIfBooked(bfst, dinner).txt
              : null}
          </div>
        );
        break;
      }
      default: {
        displayContent = `${colDbLabel}`.split('.').reduce((o,i)=> o[i] ? o[i] : '', reservation);
      }
    }

    return displayContent;
  };
  return { contructText };
};

export default ReservationRowLogic;
