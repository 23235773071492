import React from "react";
import PageHeadLine from "../PageHeadLine/PageHeadLine";

import styles from "./Page.module.css";

const Page = ({ children, new_style, title }) => {
  return (
    <>
      <PageHeadLine title={title} />
      <div className={styles.Page} style={new_style}>
        {children}
      </div>
    </>
  );
};

export default Page;
