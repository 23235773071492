import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { ReservationContext } from "../../../../context/ReservationContext";
import { addDaysToDate } from "../../../../helpingFunctions/utilities";

import TimeUtilPicker from "../../../TimeUtilPicker/TimeUtilPicker";

import styles from "./ArrivalsDate.module.css";

const ArrivalsDate = () => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { requestObject, setRequestObject } = useContext(ReservationContext);

  useEffect(() => {
    if (requestObject.arrival_date?.dbValid) {
      localStorage.setItem("arrival_date", requestObject.arrival_date?.dbValid);
    }
  }, [requestObject.arrival_date]);

  const selectedDate = moment(requestObject.arrival_date?.dbValid);

  return (
    <>
      <div className={styles.ArrivalsDate}>
        {selectedDate.isSame(new Date(), "day")
          ? "Today"
          : selectedDate.format("dddd")}
        <TimeUtilPicker
          setRequestObject={setRequestObject}
          requestObject={requestObject}
          setShowDatePicker={setShowDatePicker}
          type={"date"}
          dateType={"arrival_date"}
          format={"YYYY-MM-DD"}
          limit={7}
          wrapperStyle={{
            height: "30px",
            paddingLeft: "15px",
            marginLeft: "15px",
          }}
          inputStyle={{
            backgroundPositionY: "90%",
            backgroundSize: "20px",
            cursor: "pointer",
          }}
          muiProps={{
            minDate: new Date(),
            maxDate: addDaysToDate(7),
          }}
        />
      </div>
    </>
  );
};

export default ArrivalsDate;
