import moment from "moment";

const addDaysToDate = (days) => {
  const date = new Date();
  date.setDate(date.getDate() + days);

  return date;
};

const checkInOutStatus = (checksList, reservation) => {
  const itemInList = checksList.find(
    (checkedItem) => checkedItem.id === reservation.id
  );
  const isChecked =
    itemInList?.hasAttendant !== undefined
      ? itemInList?.hasAttendant
      : reservation.HasAttendant;

  return isChecked;
};

const findIsValidTime = (typedString, source, limit) => {
  const currentDate = new Date();
  const typedDate = new Date(typedString);

  const differenceInTime = typedDate.getTime() - currentDate.getTime();

  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  const isSameDate = currentDate.toDateString() === typedDate.toDateString();

  if (source === "DatePickerLogic")
    return (differenceInDays >= 0 && differenceInDays <= 13) || isSameDate;
  else if (source === "TimeUtilPickerLogic")
    return (
      (differenceInDays >= 0 && differenceInDays <= limit - 1) || isSameDate
    );
};

const formatDate = (date) => {
  const splittedDate = date.split("-");
  return `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
};

const getDayOfWeek = (date) => {
  try {
    return moment(date).format("dddd");
  } catch (e) {
    console.error("Error: ", e);
  }
};

const formatTime = (time) => {
  const hourMin = time.substring(0, 5).split(":");
  hourMin[0] = Number(hourMin[0]);
  hourMin[1] = Number(hourMin[1]);
  const ampm = hourMin[0] >= 12 ? "PM" : "AM";
  hourMin[0] = hourMin[0] % 12;
  hourMin[0] = hourMin[0] ? hourMin[0] : 12;
  hourMin[1] = hourMin[1] < 10 ? "0" + hourMin[1] : hourMin[1];

  return hourMin[0] + ":" + hourMin[1] + " " + ampm;
};

const formatDay = (day) => {
  const monthDay = day.substring(5).split("-");
  return `${monthDay[1]}.${monthDay[0]}`;
};

export {
  addDaysToDate,
  checkInOutStatus,
  findIsValidTime,
  formatDate,
  getDayOfWeek,
  formatTime,
  formatDay,
};
