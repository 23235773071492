import React from "react";

import SelectCard from "../SelectCard/SelectCard";
import SearchBoxLogic from "./SearchBoxLogic";

import styles from "./SearchBox.module.css";

const SearchBox = ({
  setRequestObject,
  requestObject,
  activeFiltersHandler,
  isInMediumScreen,
}) => {
  const {} = SearchBoxLogic(setRequestObject, activeFiltersHandler);

  return (
    <SelectCard
      new_style={{
        paddingRight: 0,
        marginTop: "28px",
        height: "60px",
        width: isInMediumScreen ? "100%" : "525px",
        marginBottom: isInMediumScreen ? "40px" : null,
        position: "relative",
      }}
    >
      <div className={styles.SearchBox}>
        <input
          className={styles.SearchBox__input}
          type="text"
          id="search-input"
          placeholder="Search by surname, first name or booking ref"
        />
        <div className={styles.SearchBox__iconSection}>
          <img
            className={styles.SearchBox__icon}
            src="/img/icons/icon-search.png"
            alt="no img"
          />
        </div>
      </div>
    </SelectCard>
  );
};

export default SearchBox;
