import { useContext, useEffect, useState } from "react";

import { ReservationContext } from "../../../../context/ReservationContext";
import { SelectionContext } from "../../../../context/SelectionContext";
import { getParticipants } from "../../../../helpingFunctions/DataManipulation";
import { validateFields } from "../../../../helpingFunctions/preparingParams";
import axiosInstance from "../../../../url/axiosInstance";

const ButtonSectionLogic = () => {
  const instance = axiosInstance();
  const {
    selectedReservation,
    setSelectedReservation,
    reservations,
    manipulatedReservations,
    requestObject,
    getReservations,
  } = useContext(ReservationContext);
  const { selectionsObjects, setSelectionsObjects } =
    useContext(SelectionContext);

  const [requestObjIsNotValid, setRequestObjIsNotValid] = useState(true);
  const [bookingRequestStatus, setBookingRequestStatus] = useState({
    status: "initial",
    isProcessing: false,
  });

  const checkForSkipedDays = (diningTimes) => {
    const hasBookedBreakfast = selectedReservation.Breakfast;
    const hasBookedDinner = selectedReservation.Dinner;

    if (!hasBookedBreakfast && !hasBookedDinner) return false;
    if (hasBookedBreakfast && hasBookedDinner && selectionsObjects.length <= 0)
      return true;

    const bookingDays = [
      {
        Type: "Breakfast",
      },
      {
        Type: "Dinner",
      },
    ];

    const selectedAndSkipedDays = bookingDays.filter((book) => {
      const allTypeBokings = selectionsObjects.filter(
        ({ Identifiers }) => Identifiers.IdentifierType === book.Type
      );

      if (allTypeBokings.length === bookingDays) {
        return true;
      } else {
        if (selectedReservation[book.Type]) {
          return true;
        } else {
          return false;
        }
      }
    });

    return bookingDays.length === selectedAndSkipedDays.length;
  };

  const validateObject = () => {
    const selectionsLength = selectionsObjects.length;
    const diningTimes = selectedReservation.Nights;

    const skipedDays = checkForSkipedDays(diningTimes);

    if (selectionsLength === 0 && !skipedDays) {
      setRequestObjIsNotValid(true);
      return;
    }
    const isPartial = selectionsLength < diningTimes * 2;

    if (isPartial && !skipedDays) {
      setRequestObjIsNotValid(true);
      return;
    }

    const objectHasEmptyFields = selectionsObjects.some((selection) => {
      const reqObject = {
        id: selection.id,
        type: selection.type,
        LastName: selection.LastName,
        Quantity: selection.Quantity,
        ReservationId: selection.ReservationId,
        ReservationNumber: selection.ReservationNumber,
        Date: selection.Date,
      };
      const emptyFieldObj = validateFields(reqObject);

      if (
        Object.keys(emptyFieldObj).length > 0 ||
        Object.keys(reqObject).length < 7
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (objectHasEmptyFields && !skipedDays) {
      setRequestObjIsNotValid(true);
      return;
    }

    setRequestObjIsNotValid(false);
  };

  const sendBookRequest = async () => {
    try {
      setBookingRequestStatus({
        status: "started",
        isProcessing: true,
        icon: "loading.svg",
      });

      const { data } = await instance.post("/book", selectionsObjects);

      const hasFailure = data.results.some(
        ({ Status }) => Status === "failure"
      );
      
      if (hasFailure) {
        setBookingRequestStatus({
          status: "failed",
          isProcessing: false,
          icon: "error_failed.svg",
          error:
            "There was a problem with one or many of the bookings you created. Please refresh and try again!",
        });
      } else {
        setBookingRequestStatus({
          status: "success",
          isProcessing: false,
          icon: "done_success.svg",
        });
      }

      getReservations(requestObject);
      setTimeout(() => {
        setBookingRequestStatus({ status: "initial", isProcessing: false });
      }, hasFailure ? 5000 : 2500);
    } catch (error) {
      setBookingRequestStatus({
        status: "failed",
        isProcessing: false,
        icon: "error_failed.svg",
        error: error.response.data.error,
      });
      setTimeout(() => {
        setBookingRequestStatus({ status: "initial", isProcessing: false });
      }, 2500);
      console.log(
        `${new Date()} Error in sendBookRequest func in ButtonSectionLogic.js file ${error}`
      );
    }
  };

  const filterChangesAndRequest = async () => {
    const changedReservations = manipulatedReservations.filter(
      ({ id, TableNumber }) => {
        const hasChanges = reservations.some(
          (staticRes) =>
            staticRes.id === id && staticRes.TableNumber !== TableNumber
        );

        return hasChanges;
      }
    );

    const changedReservationsLn = changedReservations.length;

    for (let index = 0; index < changedReservationsLn; index++) {
      const reservation = changedReservations[index];
      await instance.put(`dinning/arrivals/${reservation.id}`, {
        changedObject: reservation,
      });
    }
  };

  const discardChanges = () => {
    setSelectionsObjects([]);
    setSelectedReservation({});
  };

  useEffect(() => {
    validateObject();
  }, [selectionsObjects]);

  return {
    sendBookRequest,
    discardChanges,
    filterChangesAndRequest,
    bookingRequestStatus,
    requestObjIsNotValid,
  };
};

export default ButtonSectionLogic;
