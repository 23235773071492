const redirectTo = (url, targetType) => {
  const aTag = document.createElement("a");
  aTag.setAttribute("href", url);
  aTag.setAttribute("target", targetType)
  aTag.click();
  document.body.appendChild(aTag);
  document.body.removeChild(aTag);
};

export {
    redirectTo
}