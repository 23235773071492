import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";

import { ReservationContext } from "../../context/ReservationContext";

const HeaderLogic = () => {
  const { currentPage } = useContext(ReservationContext);

  //TODO: here it will be good to check against the server if the user is really logged in
  const userInfo = localStorage.getItem("userInfo");
  const isAuthenticated =
    userInfo !== "null" && userInfo !== undefined && userInfo;
  const navigate = useNavigate();

  const redirectionHandler = () => {
    if (
      isAuthenticated &&
      isAuthenticated !== "undefined" &&
      isAuthenticated !== "null"
    ) {
      if (currentPage === "" || currentPage === "callback") navigate("/home");
    } 
  };

  useEffect(() => {
    redirectionHandler();
  }, [currentPage]);

  return {
    redirectionHandler,
    currentPage,
    isAuthenticated,
  };
};

export default HeaderLogic;
