import React from 'react'

import styles from './Logo.module.css';

const Logo = ({width, height, path}) => {
    return (
        <img className={styles.Logo} src={path} width={width} height={height} alt='no img'/>
    )
}

export default Logo
