import React, { useContext, useEffect, useState } from "react";

import CheckBoxLogic from "./CheckBoxLogic";
import { ReservationContext } from "../../context/ReservationContext";

import styles from "./CheckBox.module.css";

const CheckBox = ({ parentFunction, hasAttendant, selectedReservation }) => {
  const { checkHandler } = CheckBoxLogic();
  const { manipulatedReservations } = useContext(ReservationContext);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const theReservation = manipulatedReservations.find(
      (res) => res.id === selectedReservation.id
    );
    setIsChecked(theReservation?.HasAttendant);
  }, [manipulatedReservations]);

  return (
    <span
      className={styles.CheckBoxes__checkBox}
      style={{ border: isChecked ? "none" : null }}
      onClick={checkHandler.bind(
        this,
        selectedReservation,
        parentFunction,
        hasAttendant,
        selectedReservation.Type
      )}
    >
      {isChecked ? (
        <img
          className={styles.CheckBoxes__icon}
          src={"/img/icons/done_success.svg"}
          alt="no img"
        />
      ) : null}
    </span>
  );
};

export default CheckBox;
