import React from "react";

import ToggleButtonLogic from "./ToggleButtonLogic";

import styles from "./ToggleButton.module.css";
import { GROUP_LABELS } from "../../../constants/constants";

const ToggleButton = ({action}) => {
  const { manageOnOff, isOn } = ToggleButtonLogic(action);
  return (
    <div className={styles.ToggleButton}>
      <label className={styles.ToggleButton__label}>Part of {GROUP_LABELS.GroupRef.lowerCase}</label>
      <div
        className={`${styles.ToggleButton__btn} ${
          isOn ? styles.ToggleButton__ON : styles.ToggleButton__OFF
        }`}
        onClick={manageOnOff}
      >
        <p className={styles.ToggleButton__txt}>ON</p>
        <span className={styles.ToggleButton__action}></span>
        <p className={styles.ToggleButton__txt}>OFF</p>
      </div>
    </div>
  );
};

export default ToggleButton;
