import React from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

import AlertBox from "../AlertBox/AlertBox";
import Logo from "../Logo/Logo";
import UserWelcomeModal from "../UserWelcomeModal/UserWelcomeModal";
import HeaderLogic from "./HeaderLogic";

import styles from "./Header.module.css";

const Header = () => {
  const { currentPage, isAuthenticated } = HeaderLogic();
  const { search } = useLocation();
  const navigate = useNavigate()
  const parsedQuery = queryString.parse(search);
  const isSessionExpired = parsedQuery.sessionExpired === "true";

  return (
    <>
      <div
        className={`${styles.Header_Base} ${
          isAuthenticated ? styles.Header_Auth : styles.Header_Login
        }`}
      >
        <Logo
          width={"301px"}
          height={"56px"}
          path={"/img/logo/warner_logo.png"}
        />
        <Logo
          width={"300px"}
          height={"40px"}
          path={"/img/logo/warner_new.svg"}
        />
      </div>
      {currentPage && currentPage !== "callback" ? <UserWelcomeModal /> : null}
      {isSessionExpired ? (
        <AlertBox txt={"Your session has expired please log in again."} handleClick={navigate.bind(this, "/")}/>
      ) : null}
    </>
  );
};

export default Header;
