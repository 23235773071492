import { useState } from "react";

const ToggleButtonLogic = (action) => {
  const [isOn, setIsOn] = useState(false);
  const manageOnOff = () => {
    setIsOn((prevState) => {
      action(!prevState);
      return !prevState;
    });
  };
  return { manageOnOff, isOn };
};

export default ToggleButtonLogic;
