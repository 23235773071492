const TIME_INTERVALS = [
  {
    id: 1,
    presentation: "Morning (07:00am to 11:59am)",
    startTime: "07:00",
    endTime: "11:59",
  },
  {
    id: 2,
    presentation: "Afternoon (12:00pm to 05:59pm)",
    startTime: "12:00",
    endTime: "17:59",
  },
  {
    id: 3,
    presentation: "Evening (06:00pm to 12:00am)",
    startTime: "18:00",
    endTime: "23:59",
  },
];

const FACILITY_HIERARCHY = [
  {
    category: "Dining",
    subCategories: ["Breakfast", "Dinner", "Lunch"],
  },
  {
    category: "Activity",
    subCategories: ["Activity"],
  },
  {
    category: "Experience",
    subCategories: ["Experience"],
  },
  {
    category: "Entertainment",
    subCategories: ["Venue"],
  },
];

const DINING_DETAILS = [
  {
    type: "Breakfast",
    ilegalDate: "ArrivalDate",
  },
  {
    type: "Dinner",
    ilegalDate: "DepartureDate",
  },
];

const MAXIMUM_TABLE_CAPACITY = 200;

export const GROUP_LABELS = {
  GroupRef: {
    uppercase: "Dining Party",
    lowerCase: "dining party",
    regular: "Dining party",
  },
};

const ARRIVALS_PAGES = ["activity", "dining"];
const IN_GROUP_DEFAULT = "Dinning group name:";
const IN_GROUP_DEFAULT_TYPO = "Dining group name:";
const OUT_OF_GROUP_DEFAULT = "Reservation has been removed from dining group:";
const IN_GROUP_DEFAULT_NEW = "Dining party name:";
const IN_GROUP_DEFAULT_NEW_CAPITAL = "Dining Party name:";
const OUT_OF_GROUP_DEFAULT_NEW =
  "Reservation has been removed from dining party:";

export {
  TIME_INTERVALS,
  FACILITY_HIERARCHY,
  DINING_DETAILS,
  MAXIMUM_TABLE_CAPACITY,
  ARRIVALS_PAGES,
  IN_GROUP_DEFAULT,
  OUT_OF_GROUP_DEFAULT,
  IN_GROUP_DEFAULT_NEW,
  OUT_OF_GROUP_DEFAULT_NEW,
  IN_GROUP_DEFAULT_NEW_CAPITAL,
  IN_GROUP_DEFAULT_TYPO
};
