import { useContext, useEffect, useRef, useState } from "react";

import { checkIfBooked } from "../../helpingFunctions/bookingHelp";
import { ReservationContext } from "../../context/ReservationContext";
import { compare } from "../../helpingFunctions/arrays";
import { pageRepresentation } from "../../data/data";

const ReservationTableLogic = (
  setRequestObject,
  requestObject,
  currentPage
) => {
  const {
    getReservations,
    isLoading,
    selectedReservation,
    setSelectedReservation,
    setSelectedSortCol,
    setManipulatedReservations,
    setReservations,
  } = useContext(ReservationContext);
  const [tableCol, setTableCols] = useState([]);
  const [statusSort, setStatusSort] = useState({
    isAscOrder: null,
  });

  const statusSortingHandler = (isAscOrder) => {
    requestObject.sort = null;
    setManipulatedReservations((prevState) => {
      const sortedList = prevState.sort((a, b) => {
        const aStatus = checkIfBooked(a.Breakfast, a.Dinner).txt.toLowerCase();
        const bStatus = checkIfBooked(b.Breakfast, b.Dinner).txt.toLowerCase();
        return compare(aStatus, bStatus, isAscOrder);
      });

      return sortedList;
    });

    setStatusSort({ isAscOrder });
  };

  const tableSortTypeHandler = (dbColName, isAscOrder) => {
    setTableCols((prevState) => {
      const selectedColIndex = prevState.findIndex(
        ({ dbLabel }) => dbLabel === dbColName
      );
      if (selectedColIndex >= 0) {
        prevState[selectedColIndex].isAscOrder = isAscOrder;
        prevState.map((prevItem, index) =>
          index !== selectedColIndex ? (prevItem.isAscOrder = true) : prevItem
        );
        return [...prevState];
      } else {
        return prevState;
      }
    });
  };

  // const uiSortHandler = (dbColName, isAscOrder) => {
  //   const isInReserevationObj = checkIfIsReserevationAttribute(dbColName);
  //   setManipulatedReservations((prevState) => {
  //     const sortedList = prevState.sort((a, b) => {
  //       const aValue = a[dbColName];
  //       const bValue = b[dbColName];

  //       return compare(aValue, bValue, isAscOrder);
  //     });

  //     return sortedList;
  //   });
  // };

  const sortHandler = (dbColName, isAscOrder) => {
    try {
      tableSortTypeHandler(dbColName, isAscOrder);
      setSelectedSortCol(dbColName);

      if (dbColName === "Status") {
        statusSortingHandler(isAscOrder);
        return;
      }

      setManipulatedReservations([]);
      setReservations([]);
      setRequestObject((prevState) => {
        return {
          ...prevState,
          sort: { by: dbColName, orderType: isAscOrder ? "ASC" : "DESC" },
          offset: 0,
        };
      });
      setStatusSort({ isAscOrder: null });
    } catch (error) {
      console.log(
        `${new Date()} Error in sortHandler in ReservationTableLogic.js file ${error}`
      );
    }
  };

  const rowSelectionHandler = (reservation) => {
    if (selectedReservation.id === reservation.id) {
      setSelectedReservation({});
      return;
    }
    setSelectedReservation(reservation);
  };

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    getReservations(requestObject);
  }, [requestObject]);

  useEffect(() => {
    const tableColData = pageRepresentation.tableColNames;

    setTableCols(tableColData[currentPage] ? tableColData[currentPage] : []);
  }, [currentPage]);

  useEffect(() => {
    if (statusSort.isAscOrder !== null && !requestObject.sort)
      statusSortingHandler(statusSort.isAscOrder);

    if (!requestObject?.hotel?.id) {
      setStatusSort({
        isAscOrder: null,
      });
      tableSortTypeHandler("Status", true);
    }
  }, [requestObject, isLoading]);

  return {
    sortHandler,
    rowSelectionHandler,
    tableCol,
    isLoading,
  };
};

export default ReservationTableLogic;
