import React from "react";
import SelectCard from "../SelectCard/SelectCard";
import AutoAllocationLogic from "./AutoAllocationLogic";
import AutoAllocationPreviewBox from "./AutoAllocationPreviewBox";
import styles from "./AutoAllocation.module.css";

const AutoAllocation = ({ autoAllocateAll }) => {
  const {
    fetchAutoAllocationRecords,
    isAutoAllocatePrepLoading,
    isAutoAllocatePrepTriggered,
    setIsAutoAllocatePrepTriggered,
    previewRecords,
    totalToAllocate,
    availabilityLeftOvers,
    facilityData,
    originalRecords,
    marketKitchenFacilityCodes,
    setOriginalRecords,
    setAvailabilityLeftOvers,
    totalAllocated,
    autoAllocateResult,
    setAutoAllocateResult,
    isAutoAllocateRetryLoading,
    setIsAutoAllocateRetryLoading,
    isAutoAllocateLoading,
    setIsAutoAllocateLoading,
    alertMsg,
    setAlertMsg,
    setTotalToAllocate,
  } = AutoAllocationLogic(autoAllocateAll);

  return (
    <>
      <SelectCard
        new_style={{
          backgroundColor: "#525e8b",
          height: "60px",
          width: "210px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => fetchAutoAllocationRecords(autoAllocateAll)}
      >
        <p className={styles.Autoallocate_title}>
          {autoAllocateAll ? "Auto Allocate All" : "Auto Allocate"}
        </p>
        <img
          className={styles.Autoallocate_icon}
          src="/img/icons/functionIcon/magic.svg"
          alt="no img"
        />
      </SelectCard>
      {isAutoAllocatePrepTriggered ? (
        <AutoAllocationPreviewBox
          data={previewRecords}
          isLoading={isAutoAllocatePrepLoading}
          setIsAutoAllocatePrepTriggered={setIsAutoAllocatePrepTriggered}
          totalToAllocate={totalToAllocate}
          availabilityLeftOvers={availabilityLeftOvers}
          facilityData={facilityData}
          originalRecords={originalRecords}
          marketKitchenFacilityCodes={marketKitchenFacilityCodes}
          setOriginalRecords={setOriginalRecords}
          setAvailabilityLeftOvers={setAvailabilityLeftOvers}
          autoAllocateAll={autoAllocateAll}
          autoAllocateResult={autoAllocateResult}
          setAutoAllocateResult={setAutoAllocateResult}
          totalAllocated={totalAllocated}
          isAutoAllocateRetryLoading={isAutoAllocateRetryLoading}
          setIsAutoAllocateRetryLoading={setIsAutoAllocateRetryLoading}
          isAutoAllocateLoading={isAutoAllocateLoading}
          setIsAutoAllocateLoading={setIsAutoAllocateLoading}
          alertMsg={alertMsg}
          setAlertMsg={setAlertMsg}
          setTotalToAllocate={setTotalToAllocate}
        />
      ) : null}
    </>
  );
};

export default AutoAllocation;
