import React, { useState, createContext, useEffect } from "react";
import { useLocation } from "react-router";
import moment from "moment";
import { prepareParams } from "../helpingFunctions/preparingParams";
import axiosInstance from "../url/axiosInstance";
export const ReservationContext = createContext();
const settings = {
  reservations: {
    request: {
      method: "get",
      endpoint: "/reservations",
    },
    requestDefault: {
      offset: 0,
      limit: 20,
      status: {
        id: 2,
        presentation: "No bookings",
        value: "no-booked",
      },
      sort: {
        by: "LastName",
        orderType: "ASC",
      },
    },
  },
  activity: {
    request: {
      method: "get",
      endpoint: "/activity/arrivals",
    },
    requestDefault: {
      offset: 0,
      limit: 20,
    },
  },
  dining: {
    request: {
      method: "get",
      endpoint: "/dinning/arrivals",
    },
    requestDefault: {
      offset: 0,
      limit: 20,
    },
  },
  defaultParams: {
    offset: 0,
    limit: 20,
  },
};
export const ReservationProvider = ({ children }) => {
  const instance = axiosInstance();
  const { pathname } = useLocation();
  const currentPage = pathname.substring(
    pathname.lastIndexOf("/") + 1,
    pathname.length
  );
  const [selectedReservation, setSelectedReservation] = useState({});
  const [reservations, setReservations] = useState([]);
  const [manipulatedReservations, setManipulatedReservations] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSortCol, setSelectedSortCol] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [requestObject, setRequestObject] = useState(settings.defaultParams);

  const getReservations = async (requestObject) => {
    try {
      const params = prepareParams(requestObject, currentPage);
      
      setIsLoading(true);
            
      const { endpoint } = settings[currentPage].request;
      const { data } = await instance[settings[currentPage].request.method](
        `${endpoint}`,
        {
          params,
        }
      );
      if (params.offset === 0) {
        setReservations(data);
        setManipulatedReservations(data);
      } else {
        setReservations((prevState) => [...prevState, ...data]);
        setManipulatedReservations((prevState) => [...prevState, ...data]);
      }
      setIsLoading(false);
    } catch (error) {
      setReservations([]);
      setManipulatedReservations([]);
      console.log(
        `${new Date()} Error in getReservations func in ReservationsPageLogic.js file ${
          error.message
        }`
      );
    }
  };

  const refreshReservations = async () => {
    try {
      setReservations([]);
      setManipulatedReservations([]);
      const params = prepareParams(requestObject, currentPage);
      setIsLoading(true);
      const { data } = await instance.post("/reservations/refresh", {
        params,
      });

      if (data === "Ok") {
        await getReservations(requestObject);
      }

      setIsLoading(false);
    } catch (error) {
      setReservations([]);
      setManipulatedReservations([]);
      console.log(
        `${new Date()} Error in refreshReservations func in ReservationsPageLogic.js file ${
          error.message
        }`
      );
    }
  };

  const getNumberOfItems = async () => {
    const params = prepareParams(requestObject, currentPage);
    const { endpoint } = settings[currentPage].request;
    const { data } = await instance[settings[currentPage].request.method](
      `${endpoint}/count`,
      {
        params,
      }
    );
    setTotalItems(data);
    return data;
  };
  const resetReservationDisplay = () => {
    setManipulatedReservations(reservations);
    setSelectedSortCol(null);
    setRequestObject((prevState) => {
      return {
        ...settings.defaultParams,
        hotel: prevState.hotel,
        arrival_date: ["dining", "activity"].includes(currentPage)
          ? {
              dbValid: moment(new Date()).format("YYYY-MM-DD"),
              muiValid: new Date(),
            }
          : null,
      };
    });
  };
  const updateSelectedReservation = (value, key) => {
    setSelectedReservation((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };
  const updateReservation = async (reservation) => {
    try {
      const { data } = await instance.put(
        `/reservations/comment/${reservation.id}`,
        {
          reservation,
        }
      );
      await getReservations(requestObject);
      setSelectedReservation(data.res);
    } catch (error) {
      console.error(
        `${new Date()} Error in updateReservation func inside ReservationContext ${
          error.message
        }`
      );
    }
  };
  const displayDefaultFilters = (requestObject) => {
    const nonFilterAttributes = [
      "offset",
      "limit",
      "sort",
      "hotel",
      "arrival_date",
    ];
    Object.keys(requestObject).map((key) => {
      if (!nonFilterAttributes.includes(key)) {
        setActiveFilters((prevState) => [
          ...prevState,
          {
            reqType: key,
            filterType: requestObject[key].presentation,
          },
        ]);
      }
    });
  };

  useEffect(() => {
    const hotelData = localStorage.getItem("hotel");
    setActiveFilters([]);
    setSelectedReservation({})
    if (hotelData) {
      const parsedHotelData = JSON.parse(hotelData);
      const defaultData = settings[currentPage]?.requestDefault;
      if (defaultData) {
        defaultData.hotel = parsedHotelData;
        if (["dining", "activity"].includes(currentPage)) {
          const storedDate = localStorage.getItem("arrival_date");
          defaultData.arrival_date = {
            dbValid: storedDate
              ? storedDate
              : moment(new Date()).format("YYYY-MM-DD"),
            muiValid: storedDate ? new Date(storedDate) : new Date(),
          };
        }

        setSelectedSortCol(defaultData.sort ? defaultData.sort.by : null)
        displayDefaultFilters(defaultData);
        setRequestObject(defaultData);
      } else {
        setRequestObject({
          hotel: parsedHotelData,
        });
      }
    }
  }, [currentPage]);
  // useEffect(() => {
  //   const resLn = reservations.length;
  //   if (totalItems !== 0 && resLn !== 0) {
  //     if (
  //       resLn < 10 &&
  //       totalItems > 21 &&
  //       requestObject?.sort?.by === "GroupName"
  //     ) {
  //       setRequestObject((prevState) => {
  //         return {
  //           ...prevState,
  //           offset: 21,
  //           limit: 20,
  //         };
  //       });
  //     }
  //   }
  // }, [reservations, totalItems]);
  return (
    <ReservationContext.Provider
      value={{
        isLoading,
        selectedReservation,
        manipulatedReservations,
        currentPage,
        requestObject,
        selectedSortCol,
        reservations,
        totalItems,
        activeFilters,
        setActiveFilters,
        setSelectedSortCol,
        setRequestObject,
        setSelectedReservation,
        setManipulatedReservations,
        setReservations,
        resetReservationDisplay,
        updateReservation,
        updateSelectedReservation,
        getReservations,
        getNumberOfItems,
        refreshReservations,
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};
