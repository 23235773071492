const compare = (a = "", b = "", isAscOrder) => {
  if (!a && !b) {
    return isAscOrder ? 1 : -1;
  }
  if (a > b) {
    return isAscOrder ? 1 : -1;
  }
  if (b > a) {
    return isAscOrder ? -1 : 1;
  }
  return 0;
};

const groupBy = (items, keys) => {
  const groupedArr = [];

  items.map((item) => {
    const isExisting = groupedArr.some((grItem) => {
      const filteredKeyCheck = keys.filter(
        (key) => grItem[key] === item[key] && item[key]
      );
      return filteredKeyCheck.length === keys.length;
    });

    if (!isExisting) groupedArr.push(item);
  });

  return groupedArr;
};

const groupAndReturnAllGroupedItems = (items, key) => {
  const groupedItems = {};

  items.forEach((row) => {

    if (groupedItems.hasOwnProperty(row.Type)) {
      groupedItems[row.Type].push(row);
    } else {
      groupedItems[row.Type] = [row];
    }
  })

  // items.map((item) => {
  //   const itemType = item[key];
  //   const typeCreatedIndex = groupedItems.findIndex(
  //     (grItem) => grItem[itemType]
  //   );

  //   if (typeCreatedIndex !== -1) {
  //     groupedItems[typeCreatedIndex][itemType].push(item);
  //   } else {
  //     groupedItems[itemType] = [item];
  //   }
  // });
  
  return groupedItems;
};

const ascSortObject = (object) =>
  Object.keys(object)
    .sort()
    .reduce((accumulator, key) => {
      accumulator[key] = object[key];

      return accumulator;
    }, {});

export { compare, groupBy, groupAndReturnAllGroupedItems, ascSortObject };
