import { useContext, useState, useEffect } from "react";
import { ReservationContext } from "../../context/ReservationContext";
import axiosInstance from "../../url/axiosInstance";
import {
  getDayOfWeek,
  formatTime,
  formatDay,
} from "../../helpingFunctions/utilities";

const AutoAllocationLogic = (autoAllocateAll) => {
  const instance = axiosInstance();
  const { requestObject, selectedReservation } = useContext(ReservationContext);
  const [alertMsg, setAlertMsg] = useState(null);
  const [isAutoAllocatePrepLoading, setIsAutoAllocatePrepLoading] =
    useState(false);
  const [isAutoAllocatePrepTriggered, setIsAutoAllocatePrepTriggered] =
    useState(false);
  const [previewRecords, setPreviewRecords] = useState(null);
  const [originalRecords, setOriginalRecords] = useState(null);
  const [totalToAllocate, setTotalToAllocate] = useState(0);
  const [totalAllocated, setTotalAllocated] = useState(0);
  const [availabilityLeftOvers, setAvailabilityLeftOvers] = useState(0);
  const [facilityData, setFacilityData] = useState([]);
  const [marketKitchenFacilityCodes, setMarketKitchenFacilityCodes] = useState(
    []
  );
  const [autoAllocateResult, setAutoAllocateResult] = useState(null);
  const [isAutoAllocateLoading, setIsAutoAllocateLoading] = useState(false);
  const [isAutoAllocateRetryLoading, setIsAutoAllocateRetryLoading] =
    useState(false);

  const fetchAutoAllocationRecords = async (allocateAll) => {
    setIsAutoAllocatePrepTriggered(true);
    setIsAutoAllocatePrepLoading(true);
    try {
      const params = allocateAll
        ? {
            allocateAll,
            hotelId: requestObject.hotel.strapiHotelId,
            arrival_date: requestObject.arrival_date?.dbValid,
            filter: requestObject.status.value,
          }
        : {
            allocateAll,
            hotelId: requestObject.hotel.strapiHotelId,
            arrival_date: selectedReservation.ArrivalDate.split("T")[0],
            reservationNumber: selectedReservation.ReservationNumber,
          };

      const { data } = await instance.post("/dinning/auto-allocate/prep", {
        params,
      });

      if (data.hasOwnProperty("msg")) {
        setAlertMsg(data.msg);
        setIsAutoAllocatePrepLoading(false);
        return;
      }

      await getFacilities();
      setAvailabilityLeftOvers(data.dinningAvailability);
      setOriginalRecords(data.preparedReservations);
      setMarketKitchenFacilityCodes(data.mkfc);
    } catch (error) {
      setIsAutoAllocatePrepLoading(false);
      console.log(`Auto-allocation prep failed ${error.message}`);
      setAlertMsg(`Something went wrong. Please try again later!`);
    }
  };

  const prepData = async (data) => {
    const obj = {};
    const res = new Set();
    setTotalToAllocate(data.length);
    if (autoAllocateResult) {
      setTotalAllocated(
        autoAllocateResult.filter((row) => row.Status === "success").length
      );
    }
    data.forEach((row) => {
      res.add(row.ReservationNumber);
    });

    res.forEach((row) => {
      const records = data.filter((res) => res.ReservationNumber === row);
      records.sort((a, b) => a.FacilityDate.localeCompare(b.FacilityDate));

      for (let record of records) {
        const dateTimeBooked = record.FacilityDate.split("T");
        let recordResult;
        if (autoAllocateResult) {
          recordResult = autoAllocateResult.find(
            (result) =>
              result.CRSNumber === record.CRSNumber &&
              result.Date === dateTimeBooked[0] &&
              result.Time.substring(0, 5) === dateTimeBooked[1].substring(0, 5)
          );
        }

        if (!recordResult && isAutoAllocateRetryLoading) continue;

        const recordDate = record.FacilityDate.split("T");
        const key = `${record.LastName}, ${record.FirstName} (${record.CRSNumber})`;

        if (!obj.hasOwnProperty(key)) {
          obj[key] = [];
        }

        const rowIndex = obj[key].findIndex(
          (rowI) => rowI.date === recordDate[0]
        );

        const text = `${getDayOfWeek(recordDate[0])} (${formatDay(
          recordDate[0]
        )}) - ${formatTime(recordDate[1])} ${
          facilityData.find((row) => row.id === record.activityId).Title
        }`;

        if (rowIndex === -1) {
          const prepObj = {
            date: recordDate[0],
            col2: { txt: "-", details: null },
            col3: { txt: "-", details: null },
          };

          if (record.type === "Breakfast") {
            prepObj.col2.txt = text;
            prepObj.col2.details = record;
            if (autoAllocateResult) {
              updateResult(record, prepObj, "col2");
            }
          } else {
            prepObj.col3.txt = text;
            prepObj.col3.details = record;
            if (autoAllocateResult) {
              updateResult(record, prepObj, "col3");
            }
          }

          obj[key].push(prepObj);
        } else {
          if (record.type === "Breakfast") {
            obj[key][rowIndex].col2.txt = text;
            obj[key][rowIndex].col2.details = record;
            if (autoAllocateResult) {
              updateResult(record, obj[key][rowIndex], "col2");
            }
          } else {
            obj[key][rowIndex].col3.txt = text;
            obj[key][rowIndex].col3.details = record;
            if (autoAllocateResult) {
              updateResult(record, obj[key][rowIndex], "col3");
            }
          }
        }
      }
    });

    setPreviewRecords(obj);
    setIsAutoAllocatePrepLoading(false);
    setIsAutoAllocateRetryLoading(false);
    setIsAutoAllocateLoading(false);
  };

  const getFacilities = async () => {
    const { data } = await instance.get(
      `/facilities_data/${requestObject.hotel.strapiHotelId}`
    );

    setFacilityData(
      data.filter((row) => ["Breakfast", "Dinner"].includes(row.Type))
    );
  };

  const updateResult = (record, prepObj, col) => {
    const dateTimeBooked = record.FacilityDate.split("T");
    const recordResult = autoAllocateResult.find(
      (result) =>
        result.CRSNumber === record.CRSNumber &&
        result.Date === dateTimeBooked[0] &&
        result.Time.substring(0, 5) === dateTimeBooked[1].substring(0, 5)
    );

    if (recordResult) {
      prepObj[col].status = recordResult.Status;
      prepObj[col].comment = recordResult.Comment;
    }
  };

  useEffect(() => {
    if (originalRecords) prepData(originalRecords);
  }, [originalRecords, autoAllocateResult]);

  return {
    fetchAutoAllocationRecords,
    isAutoAllocatePrepLoading,
    isAutoAllocatePrepTriggered,
    setIsAutoAllocatePrepTriggered,
    previewRecords,
    totalToAllocate,
    availabilityLeftOvers,
    facilityData,
    originalRecords,
    setOriginalRecords,
    setPreviewRecords,
    setAvailabilityLeftOvers,
    marketKitchenFacilityCodes,
    autoAllocateResult,
    setAutoAllocateResult,
    totalAllocated,
    isAutoAllocateRetryLoading,
    setIsAutoAllocateRetryLoading,
    isAutoAllocateLoading,
    setIsAutoAllocateLoading,
    alertMsg,
    setAlertMsg,
    setTotalToAllocate,
  };
};

export default AutoAllocationLogic;
