import { pickBy } from "lodash";
import moment from "moment";

const prepareParams = (requestObject, currentPage) => {
  const {
    offset,
    limit,
    arrival_date,
    tracking,
    hotel,
    status,
    search,
    sort,
    time_interval,
    checked,
    activity,
    isPartOfGroup,
  } = requestObject;

  const isInReservationsPage = currentPage === "reservations";
  const params = isInReservationsPage
    ? {
        offset: offset ? offset : requestObject.offset,
        limit: limit ? limit : requestObject.limit,
        arrival_date: arrival_date?.dbValid,
        tracking,
        hotelId: hotel?.strapiHotelId,
        status: status?.value,
        searchValue: search?.searchValue,
        isPartOfGroup: isPartOfGroup ? isPartOfGroup.value : false,
        sortBy: sort ? sort.by : "LastName",
        orderType: sort ? sort?.orderType : "ASC",
      }
    : {
        offset: offset ? offset : requestObject.offset,
        limit: limit ? limit : requestObject.limit,
        hotelId: hotel?.maestroHotelId,
        date: arrival_date ? arrival_date?.dbValid : moment(new Date()).format("YYYY-MM-DD"),
        // date: "2022-09-23",
        startTime: time_interval?.value?.startTime,
        endTime: time_interval?.value?.endTime,
        activityId: activity?.id,
        searchedKey:
          search?.searchValue && search.searchValue !== ""
            ? search.searchValue
            : undefined,
        status: checked?.value ? checked.value : false,
        sortBy: sort ? sort.by : null,
        orderType: sort ? sort.orderType : "DESC",
      };

  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });

  return params;
};

const validateFields = (originalObject) =>
  pickBy(originalObject, (v) => v === undefined || v === "" || v === null);

export { prepareParams, validateFields };
