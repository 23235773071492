import React, { useContext } from "react";

import { ReservationContext } from "../../../../context/ReservationContext";
import ArrivalDayReservationRowLogic from "./ArrivalDayReservationRowLogic";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import GroupDisplay from "./components/GroupDisplay/GroupDisplay";
import { HelperContext } from "../../../../context/HelperContext";
import { checkInOutStatus } from "../../../../helpingFunctions/utilities";

import styles from "../../ReservationTable.module.css";
import arrivals_styles from "./ArrivalsDayReservationRow.module.css";

const ArrivalsDayReservationRow = ({ reservation, onClick, tableCols }) => {
  const { selectedReservation, currentPage } = useContext(ReservationContext);
  const { allSelectedCheck } = useContext(HelperContext);
  const { constructText, checkIfSeparatedFromGroup } =
    ArrivalDayReservationRowLogic(reservation);
  const { isInMediumScreen, isInSmallScreen } = useWindowWidth();

  if (
    reservation.GroupId &&
    !reservation.isCreator &&
    checkIfSeparatedFromGroup(reservation.GroupId)
  ) {
    return null;
  }

  const isSelected = selectedReservation.id === reservation.id;
  let GroupExpansion = null;

  if (isSelected && reservation.isCreator) {
    GroupExpansion = (
      <div className={arrivals_styles.ReservationTable__row_expansion}>
        <GroupDisplay
          reservation={reservation}
          tableCols={tableCols}
          onClick={onClick}
          isInMediumScreen={isInMediumScreen}
          isInSmallScreen={isInSmallScreen}
        />
      </div>
    );
  }

  const isChecked = checkInOutStatus(allSelectedCheck, reservation);
  return (
    <div key={`Wrapper_${reservation.id}`}>
      <div
        className={`${styles.ReservationTable__row} ${
          isChecked ? arrivals_styles.ReservationTable__row_attendant : ""
        }
        ${isSelected ? arrivals_styles.ReservationTable__row_expand : ""} ${
          arrivals_styles.ReservationTable__row_arrivals
        }
        `}
        key={reservation.id}
      >
        {!isSelected || !reservation.isCreator ? (
          <>
            {tableCols.map(
              ({
                txt,
                colWidthTablet,
                colWidthDesktop,
                dbLabel,
                justifyContent,
                paddingLeft,
              }) => (
                <div
                  key={txt}
                  className={styles.ReservationTable_cell}
                  style={{
                    width: isInMediumScreen ? colWidthTablet : colWidthDesktop,
                    justifyContent,
                    cursor:
                      dbLabel === "GuestName" && reservation.Comments
                        ? "pointer"
                        : null,
                    paddingLeft:
                      isInSmallScreen && paddingLeft !== "0px"
                        ? "10px"
                        : paddingLeft,
                    color: isChecked ? "#525e8b" : "#000",
                  }}
                  onClick={
                    dbLabel === "GuestName" &&
                    (reservation.Comments || reservation.isCreator)
                      ? onClick.bind(this, reservation)
                      : null
                  }
                >
                  {constructText(txt, dbLabel, reservation, isChecked)}
                </div>
              )
            )}
          </>
        ) : (
          <>{GroupExpansion}</>
        )}
      </div>
    </div>
  );
};

export default ArrivalsDayReservationRow;
