import React, {useContext, useState} from "react";

import { checkTypes } from "../../../../../../assets/StaticObject";
import { TIME_INTERVALS } from "../../../../../../constants/constants";
import { ReservationContext } from "../../../../../../context/ReservationContext";
import DropDownBox from "../../../../../../components/DropdownBox/DropDownBox";
import ArrivalDayReservationsLogic from "./ArrivalDayReservationsLogic";

const ArrivalDayReservationsFilter = ({
  requestObject,
  manageSelection,
  selectedState,
}) => {
  const [shouldCloseDropDown, setShouldCloseDropDown] = useState(false);
  const { currentPage} = useContext(ReservationContext)
  const {
    arrivalsFacilityData,
    constructDropDowns,
    manageCategorySelection,
    setCategorySelected,
  } = ArrivalDayReservationsLogic(manageSelection);

  return (
    <>
      <DropDownBox
        selectedItem={requestObject?.activity?.presentation}
        label={currentPage === "activity" ? "Activity type" : "Dining Type"}
        placeHolder={"Select a booking type"}
        closeSubDropDowns={setCategorySelected}
        setShouldCloseDropDown={setShouldCloseDropDown}
        shouldCloseDropDown={shouldCloseDropDown}
      >
        {!shouldCloseDropDown && Object.keys(arrivalsFacilityData).map((key) => (
          <li
            onClick={(e) => {
              e.stopPropagation();
              manageCategorySelection("mainCat", key);
            }}
            key={key}
            style={{ position: "relative", textTransform: "capitalize" }}
          >
            {key}
            {constructDropDowns(key, setShouldCloseDropDown)}
            <img
              src={`/img/icons/drop-down-arrow.svg`}
              alt="no img"
              style={{
                position: "absolute",
                width: "20px",
                height: "20px",
                transform: "rotate(-90deg)",
                right: "10px",
              }}
            />
          </li>
        ))}
      </DropDownBox>
      <DropDownBox
        selectedItem={selectedState(
          TIME_INTERVALS,
          requestObject?.time_interval?.id,
          "presentation"
        )}
        label={"Start Time"}
        placeHolder={"Select a time interval"}
      >
        {TIME_INTERVALS?.map(({ id, presentation, startTime, endTime }) => (
          <li
            onClick={manageSelection.bind(
              this,
              id,
              { value: { startTime, endTime }, presentation },
              "time_interval"
            )}
            key={`${id}-${presentation}`}
          >
            {presentation}
          </li>
        ))}
      </DropDownBox>
      <DropDownBox
        selectedItem={selectedState(
          checkTypes,
          requestObject?.checked?.id,
          "Title"
        )}
        label={"Status"}
        placeHolder={"Select a status"}
      >
        {checkTypes?.map(({ id, Title, isCheckedIn }) => (
          <li
            onClick={manageSelection.bind(
              this,
              id,
              { value: isCheckedIn, presentation: Title },
              "checked"
            )}
            key={id}
          >
            {Title}
          </li>
        ))}
      </DropDownBox>
    </>
  );
};

export default ArrivalDayReservationsFilter;
