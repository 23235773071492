import React, { useContext, useEffect } from "react";

import { ReservationContext } from "../../context/ReservationContext";
import ReservationTableLogic from "./ReservationTableLogic";
import ReservationRow from "./components/ReservationsRow/ReservationRow";
import ArrivalsDayReservationRow from "./components/ArrivalsDayReservationRow/ArrivalsDayReservationRow";
import useWindowWidth from "../../hooks/useWindowWidth";
import Loader from "../UI/Loader/Loader";

import styles from "./ReservationTable.module.css";

const ReservationTable = () => {
  const {
    manipulatedReservations,
    requestObject,
    setRequestObject,
    currentPage,
    selectedSortCol,
  } = useContext(ReservationContext);
  const { isInMediumScreen, isInSmallScreen } = useWindowWidth();

  const { sortHandler, rowSelectionHandler, tableCol, isLoading } =
    ReservationTableLogic(setRequestObject, requestObject, currentPage);

  const isInAllReservationsPage = currentPage === "reservations";
  const isInActivityArrivalsPage = currentPage === "activity";

  const TableRows = isInAllReservationsPage
    ? ReservationRow
    : ArrivalsDayReservationRow;

  let tableContent = (
    <>
      {manipulatedReservations.map((reservation) => (
        <TableRows
          key={`${reservation.id}_${currentPage}`}
          reservation={reservation}
          onClick={rowSelectionHandler.bind(this, reservation)}
          tableCols={tableCol}
        />
      ))}
    </>
  );

  let LoaderEl = null;
  if (isLoading) {
    LoaderEl = (
      <div className={styles.ReservationTable__loadingState}>
        <Loader />
      </div>
    );
  } else if (manipulatedReservations.length <= 0) {
    tableContent = (
      <div className={styles.ReservationTable__noContent}>
        No content matches your request!
      </div>
    );
  }

  return (
    <div className={styles.ReservationTable__wrapper}>
      <div
        className={`${styles.ReservationTable} ${
          !isInAllReservationsPage
            ? isInActivityArrivalsPage
              ? styles.ReservationTable__arrivals
              : styles.ReservationTable__dining
            : styles.ReservationTable__portal
        }`}
      >
        <div className={styles.ReservationTable__head}>
          {tableCol?.map(
            ({
              txt,
              isAscOrder,
              dbLabel,
              paddingLeft,
              colWidthDesktop,
              colWidthTablet,
              colMinWidth,
              justifyContent,
            }) => (
              <div
                className={styles.ReservationTable_cell}
                key={txt}
                style={{
                  width: isInMediumScreen
                    ? window.innerWidth <= 807 && txt === "Surname"
                      ? "200px"
                      : colWidthTablet
                    : colWidthDesktop,
                  minWidth:
                    window.innerWidth <= 807 && txt === "Surname"
                      ? "200px"
                      : window.innerWidth <= 807 && txt === "Name"
                      ? "100px"
                      : colMinWidth,
                  justifyContent,
                  paddingLeft:
                    isInSmallScreen && paddingLeft !== "0px"
                      ? "10px"
                      : paddingLeft,
                }}
              >
                {txt}
                {dbLabel !== "HasAttendant" && dbLabel !== "Comments" ? (
                  <img
                    className={`${styles.ReservationTable__icon} ${
                      isAscOrder ? styles.ReservationTable_icon__rotate : null
                    }`}
                    style={{
                      width: selectedSortCol === dbLabel ? "20px" : "15px",
                      height: selectedSortCol === dbLabel ? "20px" : "15px",
                    }}
                    src="/img/icons/drop-down-arrow.svg"
                    alt="no img"
                    onClick={sortHandler.bind(this, dbLabel, !isAscOrder)}
                  />
                ) : null}
              </div>
            )
          )}
        </div>
        <div className={styles.ReservationTable__body} id={"table_body"}>
          {tableContent}
          {LoaderEl}
        </div>
      </div>
    </div>
  );
};

export default ReservationTable;
