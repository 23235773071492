import React from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import styles from "./SelectAndSearchDropDown.module.css";
import useWindowWidth from "../../../hooks/useWindowWidth";

const SelectAndSearchDropDown = ({ data, value, handleChange }) => {
  const selectedItem = data.find((item) => item.value === value);
  const {isInSmallScreen} = useWindowWidth()

  if (data.length <= 0) {
    return null;
  }

  return (
    <div className={styles.SelectAndSearchDropDown}>
      <Autocomplete
        disablePortal
        id="searchbox"
        options={data}
        sx={{
          height: "30px",
          padding: 0,
          width: !isInSmallScreen ? "85px" : "70px",
          background: "transparent",
        }}
        renderInput={(params) => (
          <TextField
            className={styles.SelectAndSearchDropDown_input}
            sx={{ width: "100%", backgroundColor: "transparent" }}
            {...params}
          />
        )}
        getOptionLabel={(option) => option.label}
        value={selectedItem}
        onChange={(e, value) => handleChange(value)}
      />
    </div>
  );
};

export default SelectAndSearchDropDown;
