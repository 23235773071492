import React from "react";

import SelectAndSearchDropDown from "../../../../../DropdownBox/SelectAndSearchDropDown/SelectAndSearchDropDown";
import TableNumberLogic from "./TableNumberLogic";

import styles from "./TableNumber.module.css";

const TableNumber = ({ reservation, updateList, new_styles}) => {
  const { availableTableNumbers, handleNumberChange, initialTableNumberContent} = TableNumberLogic(
    reservation,
    updateList
  );

  return (
    <div className={styles.TableNumber} style={{...new_styles}}>
      <SelectAndSearchDropDown
        data={availableTableNumbers}
        value={reservation.TableNumber || initialTableNumberContent}
        handleChange={handleNumberChange}
      />
    </div>
  );
};

export default TableNumber;
