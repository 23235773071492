import axios from "axios";
import { useNavigate } from "react-router-dom";

const AxiosInstance = () => {
  let lastTimeRedirectedToLoginPage = 0;
  const navigate = useNavigate();
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BACK_ENDPOINT,
    withCredentials: true,
    crossDomain: true,
  });

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      switch (error.response.status) {
        case 403: {
          if (
            document.location.pathname !== "/" &&
            Date.now() - lastTimeRedirectedToLoginPage > 2000
          ) {
            lastTimeRedirectedToLoginPage = Date.now();
            navigate("/?sessionExpired=true")
          }
          break;
        }
        case 401: {
          if (
            document.location.pathname !== "/" &&
            Date.now() - lastTimeRedirectedToLoginPage > 2000
          ) {
            lastTimeRedirectedToLoginPage = Date.now();
            navigate("/?sessionExpired=true")
          }
          break;
        }
        default:
          break;
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default AxiosInstance;
