import React from 'react'

import PageWall from '../PageWall/PageWall';
import Button from "../Button/Button";

import styles from "./AlertBox.module.css";

const AlertBox = ({txt, handleClick}) => {
  return (
    <PageWall source={"AlertBox"}>
        <div className={styles.AlertBox}>
            <p className={styles.alertMsg}>{txt}</p>
            <Button txt={'OK'} handleClick={handleClick}/>
        </div>
    </PageWall>
  )
}

export default AlertBox