import React from "react";

import styles from "../Button/Button.module.css";

const Button = ({ txt, handleClick, type, deactivate, new_style }) => {
  return (
    <button
      style={new_style}
      className={`${styles.Button} ${
        type === 1 ? styles.Button_style_one : styles.Button_style_two
      } ${deactivate ? styles.Button_deactivate : ""}`}
      onClick={handleClick}
    >
      {txt}
    </button>
  );
};

export default Button;
