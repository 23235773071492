import { useEffect, useState } from "react";

const useWindowWidth = () => {
  const [isInMediumScreen, setIsInMediumScreen] = useState(
    window.outerWidth <= 1400
  );

  const [isInSmallScreen, setIsInSmallScreen] = useState(
    window.outerWidth <= 950
  );

  const [height, setHeight] = useState(window.outerHeight);

  useEffect(() => {
    const handleWidthResize = () => {
      setIsInMediumScreen(window.outerWidth <= 1400);
      setIsInSmallScreen(window.outerWidth <= 950)
    };
    window.addEventListener("resize", () => {
      handleWidthResize();
      setHeight(window.outerHeight);
    });

    return () => {
      window.removeEventListener("resize", () => {
        handleWidthResize();
        setHeight(window.outerHeight);
      });
    }; 
  }, []);
  return { isInMediumScreen, isInSmallScreen, height };
};

export default useWindowWidth;
