import React from "react";

import ItinerarySelectButtonLogic from "../ItinerarySelectButtonLogic";

import styles from "../ItinerarySelectButton.module.css";

const Restaurant = ({
  id,
  new_style,
  value,
  onClick,
  dateSelected,
  typeSelected,
  isSkiped = false,
}) => {
  const { currentDaySelections } = ItinerarySelectButtonLogic(
    dateSelected,
    typeSelected
  );

  const isSelected = currentDaySelections?.id === id;
  let isAvailable = true;

  if (currentDaySelections?.Availability) {
    isAvailable =
      currentDaySelections?.Availability?.some(
        ({ FacilityGUID }) => FacilityGUID === id
      ) || currentDaySelections?.id === id;
  } else {
    isAvailable = !isSkiped;
  }

  return (
    <div
      className={`${styles.ItinerarySelectButton} ${
        isSelected ? styles.ItinerarySelectButtonHighlited : ""
      } ${!isAvailable ? styles.ItinerarySelectButtonNotAvailable : ""}`}
      style={{ ...new_style }}
      onClick={onClick}
    >
      {value}
    </div>
  );
};

export default Restaurant;
