import { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import axiosInstanceClean from "../../url/axiosInstanceClean";

const CallbackPageLogic = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const instance = axiosInstanceClean()
  const sendQueryString = async () => {
    try {
      const { code, state } = queryString.parse(location.search);

      const { data } = await instance.get(`/auth/callback`, {
        params: {
          code,
          state,
        } 
      });

      const user = await instance.get("/me", {});
      const userInfo = {
        family_name: user.data.family_name,
        given_name: user.data.given_name,
        physicalDeliveryOfficeName: "Heythrop Park",
        // physicalDeliveryOfficeName: user.data.graph.physicalDeliveryOfficeName,
      };
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      navigate("/home");
    } catch (error) {
      console.log(
        `${new Date()} Error in sendQueryString func in CallbackPageLogic.js file ${error}`
      );
    }
  };

  useEffect(() => {
    sendQueryString();
  }, []);

  return {};
};

export default CallbackPageLogic;
