import React, { useState, createContext } from "react";

export const HelperContext = createContext();

export const HelperProvider = ({ children }) => {
  const [allSelectedCheck, setAllSelectedCheck] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  
  return (
    <HelperContext.Provider
      value={{
        allSelectedCheck,
        selectedItem,
        setSelectedItem,
        setAllSelectedCheck,
      }}
    >
      {children}
    </HelperContext.Provider>
  );
};
