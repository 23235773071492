import { useContext, useEffect, useState } from "react";
import { ReservationContext } from "../../../../../../context/ReservationContext";
import { getParticipants } from "../../../../../../helpingFunctions/DataManipulation";

const GroupDisplayLogic = (reservation) => {
  const { manipulatedReservations } = useContext(ReservationContext);
  const [groupParticipants, setGroupParticipants] = useState([]);

  useEffect(() => {
    const allParticipants = getParticipants(manipulatedReservations, reservation);
    allParticipants.unshift(
        allParticipants.splice(
            allParticipants.findIndex((item) => item.isCreator),
        1
      )[0]
    );

    setGroupParticipants(allParticipants)
  },[manipulatedReservations]);
  return {groupParticipants};
};

export default GroupDisplayLogic;
