import React from "react";

import styles from "./PageWall.module.css";

const PageWall = ({ new_styles, children, source }) => {
  return (
    <div className={styles.PageWall} style={{ ...new_styles }}>
      {["Loader"].includes(source) ? (
        <div
          style={{
            background: "white",
            width: "50%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px 10px",
          }}
        >
          {children}
        </div>
      ) : (
         children 
      )}
    </div>
  );
};

export default PageWall;
