import React from "react";
import { constuctCommentsForGroups } from "../../../../../../helpingFunctions/DataManipulation";

import styles from "./SpecialRequestsList.module.css";

const SpecialRequestsList = ({ comments = "" }) => {
  const { topComments, otherComments } = constuctCommentsForGroups(comments);

  return (
    <>
      <ul className={styles.SpecialRequestsList__topComments}>
        {topComments.map((comment, index) => (
          <li key={`${comment}_${index}_${Math.random()}`}>
            <strong>
              {comment.substring(0, comment.lastIndexOf(":") + 1)}
            </strong>
            {comment.substring(comment.indexOf(":") + 1, comment.length)}
          </li>
        ))}
        {otherComments.length > 0 ? (
          <li>
            <strong>Special requests: </strong>
          </li>
        ) : null}
      </ul>
      <ul className={styles.SpecialRequestsList__otherComments}>
        {otherComments.map((comment, index) => (
          <li key={`${comment}_${index}_${Math.random()}`}>{comment}</li>
        ))}
      </ul>
    </>
  );
};

export default SpecialRequestsList;
