import React, { useState, createContext } from "react";

export const SelectionContext = createContext();

export const SelectionProvider = ({ children }) => {
  const [selectionsObjects, setSelectionsObjects] = useState([]);

  const selectionHandler = (type, value, selectedDate, typeSelected) => {
    const selectionIndex = selectionsObjects.findIndex(
      ({ Identifiers }) =>
        Identifiers &&
        Identifiers.IdentifierDate === selectedDate &&
        Identifiers.IdentifierType === typeSelected
    );

    if (selectionIndex >= 0) {
      selectionsObjects[selectionIndex][type] = value;
      selectionsObjects[selectionIndex].action = "edit";
      selectionsObjects[selectionIndex].mainpulated = true;

      setSelectionsObjects([...selectionsObjects]);
    } else {
      setSelectionsObjects((prevState) => [
        ...prevState,
        {
          [type]: value,
          action: "add",
        },
      ]);
    }
  };

  const getSelection = (selectedDate, selectedType) => {
    try {
      const selectedItem = selectionsObjects.find(
        ({ Identifiers }) =>
          Identifiers &&
          Identifiers.IdentifierDate === selectedDate &&
          Identifiers.IdentifierType === selectedType
      );

      return selectedItem;
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <SelectionContext.Provider
      value={{
        selectionsObjects,
        setSelectionsObjects,
        selectionHandler,
        getSelection,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
