import TableColName from "../models/TableColName";

const pageRepresentation = {
  tableColNames: {
    reservations: [
      new TableColName(
        "Surname",
        "LastName",
        "left",
        "30px",
        "240px",
        "200px",
        "120px"
      ),
      new TableColName(
        "Name",
        "FirstName",
        "left",
        "20px",
        "240px",
        "130px",
        "100px"
      ),
      new TableColName(
        "Booking Number",
        "CRSNumber",
        "left",
        "20px",
        "240px",
        "120px",
        "100px"
      ),
      new TableColName(
        "Party Name",
        "group.GroupName",
        "left",
        "20px",
        "240px",
        "150px",
        "100px"
      ),
      new TableColName(
        "PAX",
        "Adults",
        "left",
        "20px",
        "150px",
        "85px",
        "65px"
      ),
      new TableColName("Status", "Status", "left", "20px"),
    ],
    activity: [
      new TableColName(
        "Guest details",
        "GuestName",
        "left",
        "30px"
      ),
      new TableColName(
        "Activity",
        "Type",
        "left",
        "20px",
        "22.395833333333vw",
        "18.5vw"
      ),
      new TableColName(
        "SR",
        "Comments",
        "center",
        "0px",
        "50px",
        "50px"
      ),
      new TableColName(
        "Time",
        "Time",
        "center",
        "0px",
        "70px",
        "70px"
      ),
      new TableColName(
        "PAX",
        "Quantity",
        "center",
        "0px",
        "75px",
        "65px",
        "65px"
      ),
      new TableColName(
        "Room",
        "RoomNumber",
        "center",
        "0px",
        "80px",
        "80px"
      ),
      new TableColName(
        "Status",
        "HasAttendant",
        "center",
        "0px",
        "7.7604166666667vw",
        "80px"
      ),
      new TableColName(
        "Check In",
        "HasAttendant",
        "center",
        "0px",
        "120px",
        "80px"
      ),
    ],
    dining: [
      new TableColName(
        "Guest details",
        "GuestName",
        "left",
        "30px",
        "",
        "",
        "150px"
      ),
      new TableColName(
        "Party",
        "GroupName",
        "left",
        "20px",
        "15.104vw",
        "15.104vw"
      ),
      new TableColName(
        "Type",
        "Type",
        "left",
        "20px",
        "22.395833333333vw",
        "18.5vw"
      ),
      new TableColName(
        "SR",
        "Comments",
        "center",
        "0px",
        "50px",
        "50px"
      ),
      new TableColName(
        "Time",
        "Time",
        "center",
        "0px",
        "70px",
        "70px"
      ),
      new TableColName(
        "PAX",
        "Quantity",
        "center",
        "0px",
        "75px",
        "65px",
        "65px"
      ),
      new TableColName(
        "Room",
        "RoomNumber",
        "center",
        "0px",
        "80px",
        "80px"
      ),
      new TableColName(
        "Table #",
        "TableNumber",
        "center",
        "0px",
        "100px",
        "95px"
      ),
      new TableColName(
        "Status",
        "HasAttendant",
        "center",
        "0px",
        "7.7604166666667vw",
        "80px"
      ),
      new TableColName(
        "Check In",
        "HasAttendant",
        "center",
        "0px",
        "120px",
        "120px"
      ),
    ],
  },
};

export { pageRepresentation };
