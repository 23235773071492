import { useEffect } from "react";
import Index from "../routes";

import styles from '../css/App.module.css';

import useThemeDetector from "../hooks/useThemeDetector";
const lightSchemeIcon = document.querySelector("link#light-scheme-icon");
const darkSchemeIcon = document.querySelector("link#dark-scheme-icon");

const App = () => {
  const isDarkTheme = useThemeDetector();

  useEffect(() => {
    if (isDarkTheme) {
      lightSchemeIcon?.remove();
      document.head.append(darkSchemeIcon);
    } else {
      document.head.append(lightSchemeIcon);
      darkSchemeIcon?.remove();
    }
  }, [isDarkTheme]);


  return (
    <div className={styles.App}>
      <Index />
    </div>
  );
};

export default App;
