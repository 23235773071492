import React from "react";

import CallbackPageLogic from "./CallBackPageLogic";

import styles from "./CallBackPage.module.css";

const CallbackPage = () => {
  const {  } = CallbackPageLogic();

  return (
    <div className={styles.CallbackPage}>
        We are redirecting you to another page
    </div>
  );
};

export default CallbackPage;
