import React, { useContext } from "react";

import ReservationsFilter from "./components/ReservationsFilter/ReservationsFilter";
import FilterSectionLogic from "./FilterSectionLogic";
import AddedFilter from "../../../../components/AddedFilter/AddedFilter";
import ArrivalDayReservationsFilter from "./components/ArrivalDayReservationsFilter/ArrivalDayReservationsFilter";
import { ReservationContext } from "../../../../context/ReservationContext";
import SearchBox from "../../../../components/SearchBox/SearchBox";
import Button from "../../../../components/Button/Button";
import useWindowWidth from "../../../../hooks/useWindowWidth";

import styles from "./FilterSection.module.css";

const FilterSection = () => {
  const { isInMediumScreen } = useWindowWidth();

  const { requestObject, setRequestObject, currentPage } =
    useContext(ReservationContext);
  const {
    activeFilters,
    cleanFilterArray,
    manageSelection,
    selectedState,
    activeFiltersHandler,
  } = FilterSectionLogic();

  const isInReservationsPage = currentPage === "reservations";

  const PageFilters = isInReservationsPage
    ? ReservationsFilter
    : ArrivalDayReservationsFilter;

  return (
    <div className={styles.FilterSection}>
      <div className={styles.FilterSection__Filtes}>
        <div className={styles.FilterSection__DropDowns}>
          <SearchBox
            isInMediumScreen={isInMediumScreen}
            setRequestObject={setRequestObject}
            requestObject={requestObject}
            activeFiltersHandler={activeFiltersHandler}
          />
          <PageFilters
            requestObject={requestObject}
            setRequestObject={setRequestObject}
            selectedState={selectedState}
            cleanFilterArray={cleanFilterArray}
            activeFiltersHandler={activeFiltersHandler}
            manageSelection={manageSelection}
          />
          <Button
            txt={"Clear all"}
            handleClick={cleanFilterArray.bind(this, null, true)}
            type={1}
          />
        </div>
      </div>
      <div className={styles.FilterSection_FilterDisplay}>
        <div className={styles.FilterSection___Added_Filters}>
          {activeFilters.map(({ filterType, reqType }) => (
            <AddedFilter
              key={reqType}
              txt={filterType}
              filterReqType={reqType}
              setRequestObject={setRequestObject}
              cleanFilterArray={cleanFilterArray}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
