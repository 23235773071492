import React from "react";

import PageWall from "../PageWall/PageWall";
import styles from "./AutoAllocation.module.css";
import style from "../Loader/Loader.module.css";
import titleStyles from "../PageHeadLine/PageHeadLine.module.css";
import AutoAllocationPreviewBoxLogic from "./AutoAllocationPreviewBoxLogic";
import AutoAllocationPreviewTable from "./AutoAllocationPreviewTable";
import Button from "../Button/Button";
import AutoAllocationPreviewEdit from "./AutoAllocationPreviewEdit";
import AutoAllocationProgressBar from "./AutoAllocationProgressBar";

const AutoAllocationPreviewBox = ({
  data,
  isLoading,
  setIsAutoAllocatePrepTriggered,
  totalToAllocate,
  availabilityLeftOvers,
  facilityData,
  originalRecords,
  setOriginalRecords,
  setAvailabilityLeftOvers,
  marketKitchenFacilityCodes,
  autoAllocateAll,
  autoAllocateResult,
  setAutoAllocateResult,
  totalAllocated,
  isAutoAllocateRetryLoading,
  setIsAutoAllocateRetryLoading,
  isAutoAllocateLoading,
  setIsAutoAllocateLoading,
  alertMsg,
  setAlertMsg,
}) => {
  const {
    requestObject,
    previewEditObj,
    autoAllocateProgressId,
    autoAllocateProgressPercentage,
    areAllSuccess,
    totalAutoAllocationsSucceeded,
    setPreviewEditObj,
    initiateAutoAllocation,
    initiateAutoAllocationRetry,
    resetUponDismiss,
  } = AutoAllocationPreviewBoxLogic(
    originalRecords,
    autoAllocateAll,
    autoAllocateResult,
    setAutoAllocateResult,
    setIsAutoAllocateRetryLoading,
    setIsAutoAllocateLoading,
    setIsAutoAllocatePrepTriggered,
    setOriginalRecords,
    setAvailabilityLeftOvers,
    setAlertMsg,
  );

  return (
    <>
      <PageWall>
        <div className={styles.popupBoxWrapper}>
          <div
            className={titleStyles.PageHeadLine}
            style={{ paddingTop: "15px", height: "90%" }}
          >
            <h1
              className={titleStyles.PageHeadLine_title}
              style={{ justifyContent: "left" }}
            >
              {`Auto Allocation Summary - ${requestObject.hotel.Title}`}
            </h1>

            {isLoading ||
            isAutoAllocateLoading ||
            isAutoAllocateRetryLoading ? (
              <div
                style={{
                  height: "645px",
                  width: "100%",
                  borderTop: "2px solid #CCC",
                  marginTop: "20px",
                  paddingTop: "20px"
                }}
              >
                {isLoading ? (
                  <img
                    className={`${style.Loader_img} ${style.Loader_img_spin}`}
                    style={{
                      width: "90px",
                      height: "60px",
                      position: "absolute",
                      margin: "auto",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                    }}
                    src={`/img/icons/loading.svg`}
                    alt={""}
                  />
                ) : (
                  <AutoAllocationProgressBar
                    autoAllocateProgressPercentage={
                      autoAllocateProgressPercentage
                    }
                  />
                )}
              </div>
            ) : alertMsg ? (
              <div style={{ padding: "45px 20px", fontSize: "18px" }}>
                {alertMsg}
              </div>
            ) : (
              <>
                {!autoAllocateProgressId ? (
                  <div
                    style={{
                      width: "100%",
                      borderTop: "2px solid #CCC",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#fff3cd",
                        borderColor: "#ffeeba",
                        color: "#856404",
                        height: "60px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "18px",
                        padding: "10px 30px",
                        marginTop: "20px",
                        fontWeight: "bold",
                        borderRadius: "10px",
                        margin: "20px",
                      }}
                    >
                      Auto allocation will affect all records below. Please read
                      the below before confirming!
                    </div>
                    <AutoAllocationPreviewTable
                      data={data}
                      totalToAllocate={totalToAllocate}
                      totalAutoAllocationsSucceeded={totalAutoAllocationsSucceeded}
                      setPreviewEditObj={setPreviewEditObj}
                      isProcessed={false}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      borderTop: "2px solid #CCC",
                      marginTop: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    <AutoAllocationProgressBar
                      autoAllocateProgressPercentage={
                        autoAllocateProgressPercentage
                      }
                    />
                    {autoAllocateResult ? (
                      <AutoAllocationPreviewTable
                        data={data}
                        totalToAllocate={totalAllocated}
                        totalAutoAllocationsSucceeded={totalAutoAllocationsSucceeded}
                        setPreviewEditObj={setPreviewEditObj}
                        isProcessed={true}
                      />
                    ) : null}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderTop: "2px solid #CCC",
            paddingTop: "20px",
            // paddingBottom: "50px",
            position: "absolute",
            bottom: "5%",
            width: "90%",
            zIndex: "100",
          }}
        >
          <Button txt={"Close"} handleClick={() => resetUponDismiss()} />
          {!isLoading &&
          !isAutoAllocateLoading &&
          !isAutoAllocateRetryLoading && !alertMsg && !areAllSuccess ? (
            <Button
              txt={
                autoAllocateResult
                  ? "Retry Failed Records"
                  : "Confirm Auto-Allocation"
              }
              handleClick={() =>
                autoAllocateResult
                  ? initiateAutoAllocationRetry()
                  : initiateAutoAllocation()
              }
              new_style={{
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            />
          ) : null}
        </div>
      </PageWall>
      {previewEditObj ? (
        <AutoAllocationPreviewEdit
          availabilityLeftOvers={availabilityLeftOvers}
          previewEditObj={previewEditObj}
          setPreviewEditObj={setPreviewEditObj}
          facilityData={facilityData}
          originalRecords={originalRecords}
          setOriginalRecords={setOriginalRecords}
          setAvailabilityLeftOvers={setAvailabilityLeftOvers}
          marketKitchenFacilityCodes={marketKitchenFacilityCodes}
        />
      ) : null}
    </>
  );
};

export default AutoAllocationPreviewBox;
