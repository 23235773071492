import { useEffect, useState } from "react";
import { MAXIMUM_TABLE_CAPACITY } from "../../../../../../constants/constants";

const initialTableNumberContent = 
  {
    label: "",
    id: 500,
    value: "",
  }

const TableNumberLogic = (reservation, updateList) => {
  const [availableTableNumbers, setAvailableTableNumbers] = useState([]);

  const createListOfTableNums = () => {
    const tableNumList = [
      initialTableNumberContent
    ];
    for (let index = 0; index < MAXIMUM_TABLE_CAPACITY; index++) {
      tableNumList.push({
        label: `${index + 1}`,
        id: index + 1,
        value: index + 1,
      });
    }

    return tableNumList;
  };

  const handleNumberChange = (selectedObject = null) => {
    try {
      if (!selectedObject) {
        updateList(reservation, null);
        return;
      }

      if (selectedObject.value > 200) {
        return;
      }

      updateList(reservation, selectedObject.value);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleNumberChange func in TableNumberLogic file ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    setAvailableTableNumbers(createListOfTableNums());
  }, []);

  return { availableTableNumbers, handleNumberChange, initialTableNumberContent };
};

export default TableNumberLogic;
