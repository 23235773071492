import React from "react";

import AvailabilityLogic from "./AvailabilityLogic";
import Restaurant from "../ItinerarySelectButton/Restaurant/Restaurant";
import TimeCard from "../ItinerarySelectButton/TimeCard/TimeCard";

import styles from "./Availability.module.css";

const Availability = ({
  id,
  availability,
  facilityData,
  dateSelected,
  typeSelected,
  isOdd,
  selResQuantity,
  isSkiped
}) => {
  const { currentDayAvailability, handleRequestObject } = AvailabilityLogic(
    id,
    availability,
    dateSelected,
    typeSelected
  );

  return (
    <div
      className={`${styles.Availability} ${
        isOdd ? styles.Availability_Odd : ""
      }`}
    >
      <div className={styles.Availability__select}>
        <p className={styles.Availability__select_label}>Select time</p>
        {availability &&
        <div className={styles.Availability__time_select_button}>
          {currentDayAvailability.map(({ Time, AvailabilityList }) => (
            <TimeCard
              key={Time}
              value={Time.substr(0, 5)}
              availability={AvailabilityList}
              dateSelected={dateSelected}
              typeSelected={typeSelected}
              new_style={{ width: "80px" }}
              selResQuantity={selResQuantity}
              onClick={handleRequestObject.bind(
                this,
                "Date",
                `${dateSelected}T${Time}`,
                "Availability",
                AvailabilityList
              )}
              isSkiped={isSkiped}
            />
          ))}
        </div>
        }
      </div>
      <div className={styles.Availability__select}>
        <p className={styles.Availability__select_label}>Select place</p>
        <div className={styles.Availability__restaurant_select_button}>
          {facilityData.map(({ id, Title, Type }) =>
            Type === typeSelected ? (
              <Restaurant
                id={id}
                key={Title}
                value={Title}
                dateSelected={dateSelected}
                typeSelected={typeSelected}
                new_style={{
                  minWidth: "208px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  height: "55px"
                }}
                onClick={handleRequestObject.bind(this, "id", id)}
                isSkiped={currentDayAvailability.length > 0 ? isSkiped : true}
              />
            ) : null
          )}
        </div>
      </div>
      <div className={styles.Availability__restaurant_select}></div>
    </div>
  );
};

export default Availability;
