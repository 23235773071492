const AddedFilterLogic = (filterReqType, setRequestObject, cleanFilterArray) => {

  const removeFilter = () => {
    setRequestObject((prevState) => {
      return {
        ...prevState,
        [filterReqType]: null,
      };
    });
    cleanFilterArray(filterReqType)
  };

  return { removeFilter };
};

export default AddedFilterLogic;
