import { useContext, useEffect, useState } from "react";
import { ReservationContext } from "../../context/ReservationContext";
import { SelectionContext } from "../../context/SelectionContext";
import { compare } from "../../helpingFunctions/arrays";
import { returnBookType } from "../../helpingFunctions/bookingHelp";

const AvailabilityLogic = (id, availability, dateSelected, typeSelected) => {
  const IdentifierValues = {
    IdentifierDate: dateSelected,
    IdentifierType: typeSelected,
  };
  const { selectedReservation } = useContext(ReservationContext);
  const {
    selectionHandler,
    selectionsObjects,
    getSelection,
    setSelectionsObjects,
  } = useContext(SelectionContext);
  const { Adults, ReservationNumber, LastName } = selectedReservation;

  const [currentDayAvailability, setCurrentDayAvailability] = useState([]);

  const structureAvailabilityData = (availabilityData) => {
    const structuredData = [];

    availabilityData.Facilities.map(({ Times, FacilityGUID }) =>
      Times.map(({ Availability, Time }) => {
        const existingIndex = structuredData.findIndex(
          (item) => item.Time === Time
        );

        if (existingIndex >= 0) {
          structuredData[existingIndex].AvailabilityList.push({
            FacilityGUID,
            Availability,
          });
        } else {
          structuredData.push({
            Time,
            FacilityGUID,
            AvailabilityList: [
              {
                FacilityGUID,
                Availability,
              },
            ],
          });
        }
      })
    );

    return structuredData;
  };

  const manageSelectedDayAvailability = () => {
    try {
      const currentDayIndex = availability.findIndex(
        ({ Date }) => Date === dateSelected
      );

      if (currentDayIndex >= 0) {
        const someData = JSON.parse(
          JSON.stringify(availability[currentDayIndex])
        );

        someData.Facilities = someData.Facilities.filter(
          ({ FacilityType }) => FacilityType === typeSelected
        );

        const structuredData = structureAvailabilityData(someData);
        if (selectionsObjects.length > 0) findMissingData(structuredData);

        const sortedAvailability = structuredData.sort((a, b) =>
          compare(a.Time, b.Time, true)
        );
        setCurrentDayAvailability(sortedAvailability);
      }
    } catch (error) {
      console.log(
        `${new Date()} error in manageSelectedDayAvailability func in AvailabilityLogic.js file ${error}`
      );
    }
  };

  const findMissingData = (structuredData) => {
    try {
      const currentDaySelections = getSelection(dateSelected, typeSelected);

      if (
        !currentDaySelections ||
        !currentDaySelections.Date ||
        Object.keys(currentDaySelections).length <= 0
      )
        return;

      const extractTime = currentDaySelections.Date?.split("T")[1];
      const FacilityGUID = currentDaySelections.id;

      const isInTheListIndex = structuredData.findIndex(
        ({ Time }) => Time === extractTime
      );
      if (isInTheListIndex >= 0) {
        structuredData[isInTheListIndex].AvailabilityList.push({
          FacilityGUID,
          Availability: `${0}`,
        });
        return;
      }

      structuredData.push({
        Time: extractTime,
        FacilityGUID,
        AvailabilityList: [
          {
            FacilityGUID,
            Availability: `${0}`,
          },
        ],
      });
      selectionHandler(
        "Availability",
        [
          {
            FacilityGUID,
            Availability: `${Adults}`,
          },
        ],
        dateSelected,
        typeSelected
      );
    } catch (error) {
      console.log({ error });
    }
  };

  const setDefaultValues = () => {
    const defaultAttributes = [
      {
        type: "LastName",
        value: LastName,
      },
      {
        type: "ReservationNumber",
        value: ReservationNumber,
      },
      {
        type: "ReservationId",
        value: id,
      },
      {
        type: "Quantity",
        value: Adults,
      },
      {
        type: "type",
        value: returnBookType(typeSelected),
      },
    ];

    defaultAttributes.map(({ type, value }) =>
      selectionHandler(type, value, dateSelected, typeSelected)
    );
  };

  const findIfItemExists = (type, value) => {
    const existsIndex = selectionsObjects.findIndex(
      (selection) =>
        selection.Identifiers &&
        selection.Identifiers.IdentifierDate === dateSelected &&
        selection.Identifiers.IdentifierType === typeSelected &&
        selection[type] === value
    );

    return existsIndex;
  };

  const handleRequestObject = (type, value, type2 = null, value2 = null) => {
    const itemIndex = findIfItemExists(type, value);
    if (itemIndex >= 0) {
      const unselectedItem = selectionsObjects[itemIndex];

      setSelectionsObjects((prevState) => {
        prevState[itemIndex] = {
          Identifiers: unselectedItem.Identifiers,
          action: "remove",
        };

        return [...prevState];
      });

      return;
    }

    if (type2 && value2)
      selectionHandler(type2, value2, dateSelected, typeSelected);

    selectionHandler(type, value, dateSelected, typeSelected);
    setDefaultValues();

    if (value2 && value2.length === 1) {
      const [firstItem] = value2;

      selectionHandler(
        "id",
        firstItem.FacilityGUID,
        dateSelected,
        typeSelected
      );
    }
  };

  useEffect(() => {
    if (availability.length > 0) manageSelectedDayAvailability();
  }, [availability]);

  useEffect(() => {
    selectionHandler(
      "Identifiers",
      IdentifierValues,
      dateSelected,
      typeSelected
    );
  }, []);

  return { currentDayAvailability, handleRequestObject };
};

export default AvailabilityLogic;
