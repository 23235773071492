import { useNavigate } from "react-router-dom";
import { redirectTo } from "../../helpingFunctions/linkRedirect";

const UserWelcomeModalLogic = () => {
  const navigate = useNavigate();

  const logOutHandler = async () => {
    localStorage.clear();
    redirectTo(
      "https://login.microsoftonline.com/common/oauth2/logout",
      "_blank"
    );
    navigate("/");
  };

  const goToHomePage = async () => {
    navigate("/home");
  };

  return { logOutHandler, goToHomePage };
};

export default UserWelcomeModalLogic;
