import React, { useContext, useEffect } from "react";

import { checkIfBooked } from "../../../../helpingFunctions/bookingHelp";
import Itinerary from "../../../Itinerary/Itinerary";
import ReservationRowLogic from "./ReservationRowLogic";
import { ReservationContext } from "../../../../context/ReservationContext";
import useWindowWidth from "../../../../hooks/useWindowWidth";

import styles from "../../ReservationTable.module.css";
import reservation_row_styles from "./ReservationRow.module.css";

const ReservationRow = ({ reservation, onClick, tableCols }) => {
  const { selectedReservation } = useContext(ReservationContext);
  const { contructText } = ReservationRowLogic(
    reservation,
    selectedReservation
  );
  const { isInMediumScreen, isInSmallScreen } = useWindowWidth();
  const bfst =
    selectedReservation.id === reservation.id
      ? selectedReservation.Breakfast
      : reservation.Breakfast;
  const dinner =
    selectedReservation.id === reservation.id
      ? selectedReservation.Dinner
      : reservation.Dinner;

  return (
    <div key={`Wrapper_${reservation.id}`}>
      <div
        className={`${styles.ReservationTable__row} ${
          selectedReservation.id === reservation.id
            ? reservation_row_styles.ReservationTable__selectedRow
            : null
        } ${reservation_row_styles.ReservationTable__row_teamPortal}`}
        key={reservation.id}
        style={{
          color: checkIfBooked(bfst, dinner).color,
        }}
        onClick={onClick}
      >
        {tableCols.map(
          ({
            dbLabel,
            txt,
            colWidthTablet,
            colWidthDesktop,
            justifyContent,
            colMinWidth,
            paddingLeft,
          }) => (
            <div
              key={txt}
              className={styles.ReservationTable_cell}
              style={{
                width:
                  isInMediumScreen || isInSmallScreen
                    ? colWidthTablet
                    : colWidthDesktop,
                minWidth: colMinWidth,
                justifyContent,
                paddingLeft:
                  isInSmallScreen && paddingLeft !== "0px"
                    ? "10px"
                    : paddingLeft,
              }}
            >
              {contructText(txt, dbLabel)}
            </div>
          )
        )}
      </div>
      {selectedReservation.id === reservation.id ? (
        <div className={reservation_row_styles.ReservationTable__selectedRow}>
          <Itinerary
            id={reservation.id}
            hotelId={reservation.HotelId}
            reservationNumber={reservation.ReservationNumber}
            lastName={reservation.LastName}
            quantity={reservation.Adults}
            status={checkIfBooked(bfst, dinner).status}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ReservationRow;
