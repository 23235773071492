import React, { useContext } from "react";

import Button from "../../../../components/Button/Button";
import BookingSectionLogic from "./ButtonSectionLogic";
import Loader from "../../../../components/Loader/Loader";
import { ReservationContext } from "../../../../context/ReservationContext";

import styles from "./ButtonSection.module.css";

const ButtonSection = () => {
  const { currentPage } = useContext(ReservationContext);
  if (currentPage === "activity") {
    return null;
  }

  const {
    sendBookRequest,
    discardChanges,
    filterChangesAndRequest,
    bookingRequestStatus,
    requestObjIsNotValid,
  } = BookingSectionLogic();

  let SaveLoader = null;
  if (bookingRequestStatus.status !== "initial") {
    SaveLoader = (
      <>
        <Loader
          icon={bookingRequestStatus.icon}
          isLoading={bookingRequestStatus.isProcessing}
          additionalTxt={bookingRequestStatus.error}
          new_style_icon={{ width: "50px", height: "50px" }}
        />
      </>
    );
  }

  let Buttons = null;

  if (currentPage === "reservations") {
    Buttons = (
      <div className={styles.ButtonSection}>
        <Button
          txt={"Save"}
          handleClick={sendBookRequest}
          type={1}
          deactivate={requestObjIsNotValid}
        />
        <Button txt={"Discard"} handleClick={discardChanges} type={2} />
      </div>
    );
  } else if (currentPage === "dining") {
    Buttons = (
      <div className={styles.ButtonSection}>
        <Button
          txt={"Save"}
          handleClick={filterChangesAndRequest}
          type={1}
        />
      </div>
    );
  }

  return (
    <>
      {Buttons}
      {SaveLoader}
    </>
  );
};

export default ButtonSection;
