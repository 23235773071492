import React, { useContext } from "react";

import UserWelcomeModalLogic from "./UserWelcomeModalLogic";
import { ReservationContext } from "../../context/ReservationContext";
import ArrivalsDate from "./components/ArrivalsDate/ArrivalsDate";

import styles from "./UserWelcomeModal.module.css";

const UserWelcomeModal = () => {
  const { currentPage } = useContext(ReservationContext);
  const { logOutHandler, goToHomePage } = UserWelcomeModalLogic();

  const isInArrivalsPage = ["dining","activity"].includes(currentPage);
  const isInHomePage = currentPage === "home";

  const userString = localStorage.getItem("userInfo");
  let fName = null;
  if (userString) {
    const user = JSON.parse(userString);
    fName = user.given_name;
  }

  return (
    <div
      className={`${styles.UserWelcomeModal} ${
        isInArrivalsPage ? styles.UserWelcomeModal_arrival : null
      }`}
      style={{
        justifyContent: isInArrivalsPage ? "space-between" : null,
        paddingLeft: isInHomePage ? "20px" : "65px"
      }}
    >
      {!isInHomePage ? (
        <img
          src="/img/icons/functionIcon/home.svg"
          className={styles.UserWelcomeModal__backIcon}
          onClick={goToHomePage}
          alt="no img"
        />
      ) : null}

      {isInArrivalsPage ? (
        <ArrivalsDate/>
      ) : null}
      {userString ? (
        <div className={styles.UserWelcomeModal__userInfo}>
          <h3 className={styles.UserWelcomeModal__title}>
            Hello, {fName}
          </h3>
          <p
            className={styles.UserWelcomeModal__logOut}
            onClick={logOutHandler}
          >
            Log Out
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default UserWelcomeModal;
