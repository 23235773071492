const filterBy = [
  {
    id: 1,
    status: "Booked",
    hasBooked: "booked",
  },
  {
    id: 2,
    status: "No bookings",
    hasBooked: "no-booked",
  },
  {
    id: 3,
    status: "Partially Booked",
    hasBooked: "partially-booked",
  },
];

const bookingTypes = [
  { id: 1, Title: "Breakfast" },
  { id: 2, Title: "Dinner" },
  { id: 3, Title: "Activity" },
  { id: 4, Title: "Entertainment" },
];
const checkTypes = [
  { id: 1, Title: "Checked in", isCheckedIn: true },
  { id: 2, Title: "Not Checked in", isCheckedIn: false },
];


export { filterBy, bookingTypes, checkTypes };
