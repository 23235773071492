import React from "react";
import styles from "./AutoAllocation.module.css";
import PageWall from "../PageWall/PageWall";
import titleStyles from "../PageHeadLine/PageHeadLine.module.css";
import timeBtnsStyles from "../Availability/Availability.module.css";
import restStyles from "../ItinerarySelectButton/ItinerarySelectButton.module.css";
import Button from "../Button/Button";
import AutoAllocationPreviewEditLogic from "./AutoAllocationPreviewEditLogic";

const AutoAllocationPreviewEdit = ({
  previewEditObj,
  availabilityLeftOvers,
  setPreviewEditObj,
  facilityData,
  originalRecords,
  setOriginalRecords,
  setAvailabilityLeftOvers,
  marketKitchenFacilityCodes,
}) => {
  const venueType = previewEditObj.col.details.type;
  const venueTypeSm = venueType === "Breakfast" ? "bfst" : "dinner";
  const selectedDateTime = previewEditObj.col.details.FacilityDate.split("T");
  const {
    selection,
    restaurantType,
    isRestaurantAvailable,
    updateSelection,
    updateAutoAllocationSelection,
  } = AutoAllocationPreviewEditLogic(
    venueTypeSm,
    selectedDateTime,
    marketKitchenFacilityCodes,
    facilityData,
    previewEditObj,
    availabilityLeftOvers,
    originalRecords,
    setOriginalRecords,
    setAvailabilityLeftOvers,
    setPreviewEditObj,
  );

  return (
    <PageWall>
      <div
        className={styles.popupBoxWrapper}
        style={{ width: "80%", height: "60%", top: "25%" }}
      >
        <h1
          className={titleStyles.PageHeadLine_title}
          style={{
            justifyContent: "left",
            fontSize: "20px",
            padding: "0 32px",
          }}
        >
          {`Editing - ${previewEditObj.key} - ${previewEditObj.col.txt} for ${previewEditObj.col.details.FacilityQuantity} guests`}
        </h1>

        {selection && restaurantType ? (
          <>
            <div style={{ padding: "20px 15px 35px 15px", width: "100%" }}>
              <div className={timeBtnsStyles.Availability__select}>
                <p className={timeBtnsStyles.Availability__select_label}>
                  Select time
                </p>
                <div
                  className={timeBtnsStyles.Availability__time_select_button}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gridColumnGap: "15px",
                  }}
                >
                  {availabilityLeftOvers[venueTypeSm][restaurantType][
                    selectedDateTime[0]
                  ].map(({ time, Quantity }) => (
                    <div
                      className={`${restStyles.ItinerarySelectButton} ${
                        time === selection.time
                          ? restStyles.ItinerarySelectButtonHighlited
                          : ""
                      } 
                      ${
                        previewEditObj.col.details.FacilityQuantity > Quantity
                          ? restStyles.ItinerarySelectButtonNotAvailable
                          : ""
                      }
    `}
                      style={{ padding: "5px", width: "90px", display: "flex" }}
                      // style={{ ...new_style }}
                      onClick={() => updateSelection("time", time)}
                    >
                      {time.substring(0, 5)}
                      {`[${Quantity}]`}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              className={timeBtnsStyles.Availability__select}
              style={{ padding: "20px 15px 35px 15px", width: "100%" }}
            >
              <p className={timeBtnsStyles.Availability__select_label}>
                Select place
              </p>
              <div
                className={
                  timeBtnsStyles.Availability__restaurant_select_button
                }
                style={{
                  display: "flex",
                  gridColumnGap: "14px",
                  flexWrap: "wrap",
                }}
              >
                {facilityData.map(({ id, Title, Type, FacilityCode }) =>
                  Type === venueType ? (
                    <div
                      className={`${restStyles.ItinerarySelectButton} ${
                        id === selection.restaurant
                          ? restStyles.ItinerarySelectButtonHighlited
                          : ""
                      } 
                      ${
                        isRestaurantAvailable[FacilityCode]
                          ? restStyles.ItinerarySelectButtonNotAvailable
                          : ""
                      }
                    `}
                      style={{ padding: "25px 30px", display: "flex" }}
                      onClick={() =>
                        updateSelection("restaurant", id, FacilityCode)
                      }
                    >
                      {Title}
                    </div>
                  ) : null
                )}
              </div>
            </div>
            <div
              className={timeBtnsStyles.Availability__restaurant_select}
            ></div>

            <div
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                txt={"Close"}
                handleClick={() => setPreviewEditObj(null)}
              />
              <Button
                txt={"Confirm"}
                handleClick={() => updateAutoAllocationSelection()}
                new_style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              />
            </div>
          </>
        ) : null}
      </div>
    </PageWall>
  );
};

export default AutoAllocationPreviewEdit;
