import React, { useContext, useEffect } from "react";

import ReservationTable from "../../components/ReservationTable/ReservationTable";
import FilterSection from "./components/FilterSection/FilterSection";
import ButtonSection from "./components/ButtonSection/ButtonSection";
import PaginationSectionLogic from "./components/PaginationSection/PaginationSectionLogic";
import Page from "../../components/Page/Page";
import { SelectionProvider } from "../../context/SelectionContext";
import { ReservationContext } from "../../context/ReservationContext";

import styles from "./ReservationsPage.module.css";

const ReservationsPage = () => {
  const { currentPage, setRequestObject } = useContext(ReservationContext);
  const {} = PaginationSectionLogic(currentPage);
  let pageTitle = null;
  if (currentPage === "reservations") {
    pageTitle = "Dining Allocation";
  } else if (currentPage === "dining") {
    pageTitle = "Dining Arrivals";
  } else {
    pageTitle = "Activity Arrivals";
  }

  useEffect(() => {
    return () => {
      localStorage.removeItem("arrival_date");
      setRequestObject((prevState) => {
        return {
          ...prevState,
          arrival_date: null,
        };
      });
    };
  }, []);
  return (
    <SelectionProvider>
      <Page title={pageTitle}>
        <div className={styles.ReservationsPage}>
          <FilterSection />
          <ReservationTable />
          <ButtonSection />
        </div>
      </Page>
    </SelectionProvider>
  );
};

export default ReservationsPage;
