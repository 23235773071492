import { useContext, useEffect, useState } from "react";
import DropDownList from "../../../../../../components/DropdownBox/components/DropDownList/DropDownList";

import { ReservationContext } from "../../../../../../context/ReservationContext";
import { groupAndReturnAllGroupedItems } from "../../../../../../helpingFunctions/arrays";
import { prepareParams } from "../../../../../../helpingFunctions/preparingParams";

import axiosInstance from "../../../../../../url/axiosInstance";

const ArrivalDayReservationsLogic = (manageSelection) => {
  const instance = axiosInstance();
  const { requestObject, currentPage } = useContext(ReservationContext);
  const [arrivalsFacilityData, setArrivalsFacilityData] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});

  const getFacilityTitles = async () => {
    if (!requestObject.hotel) return;
    const extractData = {
      arrival_date: requestObject.arrival_date,
      hotel: requestObject.hotel,
    };

    const { date, hotelId, limit, offset } = prepareParams(
      extractData,
      currentPage
    );

    const facilitiesTitles = {};

    if (currentPage === "dining") {
      const { data: dining } = await instance.get(
        "dinning/arrivals/facility/title",
        {
          params: { date, hotelId, limit, offset },
        }
      );

      const groupedDining = groupAndReturnAllGroupedItems(dining, "Type");

      facilitiesTitles.breakfast = groupedDining.Breakfast;
      facilitiesTitles.lunch = groupedDining.Lunch ? groupedDining.Lunch : [];
      facilitiesTitles.dinner = groupedDining.Dinner;
    } else {
      const { data: activity } = await instance.get(
        "activity/arrivals/facility/title",
        {
          params: { date, hotelId, limit, offset },
        }
      );


      const groupedActivities = groupAndReturnAllGroupedItems(activity, "Type");

      const experiences = activity.filter(({ Type }) => Type === "Experiences");

      delete groupedActivities.Experiences
      facilitiesTitles.activity = groupedActivities
      facilitiesTitles.experiences = experiences;

      if (requestObject.hotel.hasEnts === "HP") {
        const { data: entertainment } = await instance.get(
          "entertainment/arrivals/facility/title",
          {
            params: { date, hotelId, limit, offset },
          }
        );

        facilitiesTitles.entertainment = entertainment;
      }
    }

    setArrivalsFacilityData(facilitiesTitles);
  };

  const manageCategorySelection = (type, cat) => {
    setCategorySelected((prevState) => {
      return {
        ...prevState,
        [type]: cat,
      };
    });
  };

  const constructDropDowns = (key, setShouldCloseDropDown) => {
    if (categorySelected.mainCat !== key) {
      return null;
    }
    const singleSubLevel = [
      "entertainment",
      "experiences",
      "breakfast",
      "lunch",
      "dinner",
    ];

    if (key === "activity") {
      return (
        <DropDownList
          isClicked={categorySelected.mainCat === key}
          new_styles={{
            left: "104%",
            top: "0",
            zIndex: 10,
            boxShadow: "none",
            border: "2px solid gray",
          }}
        >
          {Object.keys(arrivalsFacilityData[key]).map((subCat, index) => (
            <li
              onClick={manageCategorySelection.bind(this, "subCat", subCat)}
              key={subCat}
              style={{ position: "relative" }}
            >
              {subCat}
              <img
                src={`/img/icons/drop-down-arrow.svg`}
                alt="no img"
                style={{
                  position: "absolute",
                  width: "20px",
                  height: "20px",
                  transform: "rotate(-90deg)",
                  right: "10px",
                }}
              />
              {categorySelected.subCat === subCat ? (
                <DropDownList
                  isClicked={true}
                  new_styles={{
                    left: "104%",
                    top: "-2px",
                    zIndex: 11,
                    boxShadow: "none",
                    border: "2px solid gray",
                  }}
                >
                  {arrivalsFacilityData[key][subCat]?.map(
                    ({ ActivityId, ActivityTitle }) => (
                      <li
                        onClick={(e) => {
                          manageSelection(
                            ActivityId,
                            {
                              value: ActivityId,
                              presentation: `${subCat} - ${ActivityTitle}`,
                            },
                            "activity"
                          );
                          setCategorySelected({});
                          setShouldCloseDropDown(true);
                        }}
                        key={ActivityId}
                        style={{ position: "relative" }}
                      >
                        {ActivityTitle}
                      </li>
                    )
                  )}
                </DropDownList>
              ) : null}
            </li>
          ))}
        </DropDownList>
      );
    } else if (singleSubLevel.includes(key)) {
      return (
        <DropDownList
          isClicked={true}
          new_styles={{
            left: "104%",
            top: "0",
            zIndex: 10,
            boxShadow: "none",
            border: "2px solid gray",
          }}
        >
          {arrivalsFacilityData[key].map(({ ActivityId, ActivityTitle }) => (
            <li
              onClick={() => {
                manageSelection(
                  ActivityId,
                  {
                    value: ActivityId,
                    presentation: `${key} - ${ActivityTitle}`,
                  },
                  "activity"
                );
                setCategorySelected({});
                setShouldCloseDropDown(true);
              }}
              key={ActivityTitle}
              style={{ position: "relative" }}
            >
              {ActivityTitle}
            </li>
          ))}
        </DropDownList>
      );
    }
  };

  useEffect(() => {
    getFacilityTitles();
  }, [requestObject.arrival_date]);

  return {
    categorySelected,
    arrivalsFacilityData,
    setCategorySelected,
    manageCategorySelection,
    constructDropDowns,
  };
};

export default ArrivalDayReservationsLogic;
