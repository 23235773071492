import { useContext, useEffect, useState } from "react";

import { ReservationContext } from "../../../../context/ReservationContext";

const itemsPerPage = 20;

const PaginationSectionLogic = () => {
  const {
    getNumberOfItems,
    requestObject,
    setRequestObject,
  } = useContext(ReservationContext);
  const [selectedNumber, setSelectedNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);

  const calculateTotalPages = (totalItems) => {
    const parsedTotalItems = parseInt(totalItems);

    const pagesNeeded = parsedTotalItems / itemsPerPage;
    if (pagesNeeded % 1 !== 0) {
      return Math.floor(pagesNeeded) + 1;
    }

    return pagesNeeded;
  };

  const getTotalItemsNum = async () => {
    try {
      const data = await getNumberOfItems();

      const totalPagesNum = calculateTotalPages(data);
      setTotalPages(totalPagesNum);

      if (totalPagesNum < selectedNumber + 1) {
        setSelectedNumber(0);
      }
    } catch (error) {
      console.log(
        `${new Date()} Error in totalItems func in PaginationSectionLogic.js file ${error}`
      );
    }
  };

  const offset_limit_handler = (selectedNumber) => {
    if (selectedNumber >= totalPages || selectedNumber === 0) {
      return;
    }
    const jump = (Math.floor(requestObject.offset / 20) + 1) * itemsPerPage;
    const offset = jump;
    setRequestObject((prevState) => {
      return { ...prevState, limit: itemsPerPage, offset };
    });
  };

  useEffect(() => {
    offset_limit_handler(selectedNumber);
  }, [selectedNumber]);

  useEffect(() => {
    if (requestObject.hotel) {
      getTotalItemsNum(requestObject);
    }
  }, [requestObject]);

  useEffect(() => {
    const table_body = document.getElementById("table_body");

    table_body.addEventListener("scroll", () => {
      const scrollingPos = table_body.clientHeight + table_body.scrollTop;

      const endingReached =
        Math.abs(scrollingPos - table_body.scrollHeight) < 2;

      if (endingReached) {
        setSelectedNumber((prevState) => prevState + 1);
      }
    });
  }, []);

  return {};
};

export default PaginationSectionLogic;
