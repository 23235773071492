import { useContext, useState } from "react";
import { ReservationContext } from "../../../../context/ReservationContext";

const SpecialRequestsLogic = () => {
  const { selectedReservation, updateReservation } =
    useContext(ReservationContext);
  const [addedSpecialRequest, setAddedSpecialRequest] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const updateSpecialReq = (e) => {
    const { value } = e.target;
    setAddedSpecialRequest(value);
  };

  const handleSpecialRequestSave = async () => {
    setIsLoading(true);
    await updateReservation({
      ...selectedReservation,
      addedSpecialRequest,
    });
    setAddedSpecialRequest("");
    setIsLoading(false);
  };

  return {
    addedSpecialRequest,
    isLoading,
    updateSpecialReq,
    handleSpecialRequestSave,
  };
};

export default SpecialRequestsLogic;
