import React from "react";

import LoginLinkPageLogic from "./LoginLinkPageLogic";
import Logo from "../../components/Logo/Logo";
import Button from "../../components/Button/Button";

import styles from "./LoginLinkPage.module.css";

const LoginLinkPage = () => {
  const { loginHandler } = LoginLinkPageLogic();

  return (
    <div className={styles.LoginLinkPage}>
      <div className={styles.LoginLinkPage__Box}>
        <Logo
          width={"301px"}
          height={"56px"}
          path={"/img/logo/warner_new.svg"}
        />
        <p className={styles.LoginLinkPage__Desc}>
          Welcome to the My Warner Stay Team Portal. This portal will allow you
          to administer dining bookings, and check in our Guests for their
          activities. Keep checking back for new features. Your feedback is
          important to us, so please use{" "}
          <a target={"_blank"} href="https://forms.office.com/r/9ch1kdPidk">
            https://forms.office.com/r/9ch1kdPidk
          </a>{" "}
          if you would like request a new feature or suggest improvements.
        </p>
        <Button txt={"Log in"} handleClick={loginHandler} type={1} />
      </div>
    </div>
  );
};

export default LoginLinkPage;
