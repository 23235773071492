import React, { useContext } from "react";
import { useNavigate } from "react-router";
import Button from "../../components/Button/Button";
import DropDownBox from "../../components/DropdownBox/DropDownBox";
import Page from "../../components/Page/Page";
import { ReservationContext } from "../../context/ReservationContext";
import HomeLogic from "./HomeLogic";

import styles from "./Home.module.css";

const buttonStyles = {
  height: "85px",
  width: "auto",
  paddingLeft: "50px",
  paddingRight: "50px",
};
const hotelDropDownStyles = {
  label: {
    marginBottom: "30px",
  },
  dropDown: {
    width: "475px",
    height: "50px",
  },
};
const Home = () => {
  const navigate = useNavigate();
  const { requestObject } = useContext(ReservationContext);

  const { hotels, handleHotelSelection } = HomeLogic();

  const userString = localStorage.getItem("userInfo");
  let fName, lName;
  if (userString) {
    const user = JSON.parse(userString);
    lName = user.family_name;
    fName = user.given_name;
  }
  return (
    <Page>
      <div className={styles.Home}>
        <div className={styles.Home__Welcome}>
          <h3 className={styles.Home__Welcome_title}>Hello, {fName}</h3>
          <p className={styles.Home__Welcome_desc}>
            Welcome to "My Warner Stay" Admin interface.
          </p>
        </div>
        <div className={styles.Home__Navigate}>
          <DropDownBox
            selectedItem={requestObject?.hotel?.Title}
            label={"Choose the hotel"}
            placeHolder={"Please Select Hotel"}
            hotelDropDownStyles={hotelDropDownStyles}
          >
            {hotels?.map(({ id, Title, HotelId, TwoLetterCode }) => (
              <li
                onClick={handleHotelSelection.bind(this, {
                  id,
                  Title: Title,
                  strapiHotelId: id,
                  maestroHotelId: HotelId,
                  hasEnts: TwoLetterCode
                })}
                key={id}
              >
                {Title}
              </li>
            ))}
          </DropDownBox>
          <div className={styles.Home__Buttons}>
            <Button
              new_style={buttonStyles}
              type={1}
              txt={"Dining Allocation"}
              handleClick={navigate.bind(this, "/reservations")}
              deactivate={!requestObject?.hotel?.Title}
            />
            <Button
              new_style={buttonStyles}
              type={1}
              txt={"Activity Arrivals"}
              handleClick={navigate.bind(this, "/arrivals/activity")}
              deactivate={!requestObject?.hotel?.Title}
            />
            <Button
              new_style={buttonStyles}
              type={1}
              txt={"Dining Arrivals"}
              handleClick={navigate.bind(this, "/arrivals/dining")}
              deactivate={!requestObject?.hotel?.Title}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Home;
