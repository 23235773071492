import React from "react";

import SelectCard from "../../../SelectCard/SelectCard";
import ItineraryDropDownLogic from "./ItineraryDropDownLogic";
import Availability from "../../../Availability/Availability";

import styles from "./ItineraryDropDowns.module.css";

const ItineraryDropDowns = ({
  id,
  label,
  icon,
  itineraryData,
  availability,
  facilityData,
  isOdd,
  quantity,
}) => {
  const { selectedBox, contructSelectedTextDisplayed, isNonSelectableDate } =
    ItineraryDropDownLogic(
      id,
      label,
      facilityData,
      availability,
      isOdd,
      quantity
    );

  return (
    <div className={styles.ItineraryDropDowns}>
      <div className={styles.ItineraryDropDowns__label_wrapper}>
        <img
          className={styles.ItineraryDropDowns__Icon}
          src={`img/icons/${icon}.svg`}
          alt={"no img"}
        />
        <p className={styles.ItineraryDropDowns__Label}>{label}</p>
      </div>
      <div className={styles.ItineraryDropDowns__selectBoxes}>
        {itineraryData[label]?.map((itineraryDay, index) => (
          <SelectCard
            new_style={{
              ...propStyles.selectCard,
              opacity: isNonSelectableDate(label, itineraryDay?.Date)
                ? "0.3"
                : "1",
              pointerEvents: isNonSelectableDate(label, itineraryDay?.Date)
                ? "none"
                : "auto",
            }}
            key={itineraryDay?.Date}
          >
            {contructSelectedTextDisplayed(
              selectedBox,
              itineraryDay,
              label,
              label === "Breakfast" && index === 0,
              label === "Dinner" && index === itineraryData[label].length - 1
            )}
          </SelectCard>
        ))}
      </div>
    </div>
  );
};

const propStyles = {
  selectCard: {
    width: "195px",
    height: "33px",
    position: "relative",
  },
};
export default ItineraryDropDowns;
