import { useContext, useEffect, useState } from "react";
import { ARRIVALS_PAGES } from "../../../../constants/constants";
import { ReservationContext } from "../../../../context/ReservationContext";

const FilterSectionLogic = () => {
  const {
    setRequestObject,
    resetReservationDisplay,
    activeFilters,
    setActiveFilters,
    currentPage,
  } = useContext(ReservationContext);

  const selectedState = (
    list,
    itemId,
    selectAttribute,
    compareAttribute = "id"
  ) => {
    try {
      if (!list || !itemId) return;

      const item = list.find((item) => item[compareAttribute] === itemId);

      return item[selectAttribute];
    } catch (error) {}
  };

  const manageSelection = (id, content, type) => {
    setRequestObject((prevState) => {
      return {
        ...prevState,
        [type]: { id, ...content },
        offset: 0,
      };
    });

    if (ARRIVALS_PAGES.includes(currentPage) && type === "arrival_date") {
      return;
    }

    activeFiltersHandler({ reqType: type, filterType: content.presentation });
  };

  const activeFiltersHandler = (newFilter) => {
    const filterIndex = activeFilters.findIndex(
      ({ reqType }) => reqType === newFilter.reqType
    );

    if (filterIndex >= 0) {
      if (!newFilter.filterType) cleanFilterArray(newFilter.reqType);

      activeFilters[filterIndex].filterType = newFilter.filterType;
      return;
    }

    if (!newFilter.filterType) return;
    setActiveFilters((prevState) => [...prevState, newFilter]);
  };

  const cleanSearchInput = () => {
    const input = document.querySelector("#search-input");
    input.value = "";
  };
  const cleanFilterArray = (filterReqType, cleanAll) => {
    if (cleanAll) {
      setActiveFilters([]);
      resetReservationDisplay();
      cleanSearchInput();
      localStorage.removeItem("arrival_date");
      return;
    }
    const cleanedArray = activeFilters.filter(
      ({ reqType }) => reqType !== filterReqType
    );

    setActiveFilters(cleanedArray);

    if (filterReqType === "search") {
      cleanSearchInput();
    }
  };

  return {
    activeFilters,
    manageSelection,
    selectedState,
    activeFiltersHandler,
    cleanFilterArray,
  };
};

export default FilterSectionLogic;
