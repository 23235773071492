import { useContext, useEffect, useState } from "react";
import { DINING_DETAILS } from "../../constants/constants";
import { ReservationContext } from "../../context/ReservationContext";
import { SelectionContext } from "../../context/SelectionContext";
import { returnBookType } from "../../helpingFunctions/bookingHelp";
import axiosInstance from "../../url/axiosInstance";

const ItineraryLogic = (
  reservationId,
  hotelId,
  quantity,
  reservationNumber,
  lastName,
  autoAllocateAll
) => {
  const instance = axiosInstance();
  const [itineraryData, setItineraryData] = useState({});
  const [availability, setAvailability] = useState([]);
  const [facilityData, setFacilityData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setSelectionsObjects } = useContext(SelectionContext);
  const {
    setSelectedReservation,
    selectedReservation,
    manipulatedReservations,
    setManipulatedReservations,
    requestObject,
  } = useContext(ReservationContext);
  const getAvailability = async () => {
    try {
      const { data } = await instance.get(`/availability`, {
        params: {
          id: reservationId,
          quantity,
          facility_types: ["Breakfast", "Dinner"],
        },
      });
      setAvailability(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFacilities = async () => {
    const { data } = await instance.get(`/facilities_data/${hotelId}`);

    setFacilityData(data);
  };

  const irregularBookingDataHandler = (updatedResDetails) => {
    try {
      const dataCopy = JSON.parse(JSON.stringify(manipulatedReservations));
      const resIndex = dataCopy.findIndex((row) => row.id === reservationId);
      dataCopy[resIndex].Breakfast = updatedResDetails.Breakfast;
      dataCopy[resIndex].Dinner = updatedResDetails.Dinner;
      setSelectedReservation({});
      setManipulatedReservations(
        dataCopy.filter((row) => {
          if (requestObject?.status?.value === "no-booked") {
            return !row.Dinner && !row.Breakfast;
          } else if (requestObject?.status?.value === "partially-booked") {
            return !row.Dinner || !row.Breakfast;
          } else {
            return row;
          }
        })
      );
    } catch (error) {
      console.error(
        `${new Date()} Error in irregularBookingDataHandler func in ItineraryLogic.js ${
          error.message
        }`
      );
    }
  };

  const getItinerary = async () => {
    try {
      const { data } = await instance.get(`/itinerary/${reservationId}`);
      const { Dates, updatedResDetails } = data;
      if (
        updatedResDetails.Dinner !== selectedReservation.Dinner ||
        selectedReservation.Breakfast !== updatedResDetails.Breakfast
      ) {
        irregularBookingDataHandler(updatedResDetails);
        return;
      }

      const itineraryDates = Object.keys(Dates).map((key) => {
        return { Date: key, ...Dates[key] };
      });

      const breakfastItinerary = contructItinerayByType(
        itineraryDates,
        "Breakfast"
      );
      const dinnerItinerary = contructItinerayByType(itineraryDates, "Dinner");
      checkForItinerary(itineraryDates);
      setItineraryData({
        ItineraryData: itineraryDates,
        Breakfast: breakfastItinerary,
        Dinner: dinnerItinerary,
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const contructItinerayByType = (itineraryDates, type) => {
    const bookedFacilities = itineraryDates.map(({ Date, Facilities }) => {
      const [bookedFacility] = Facilities.filter(({ Type }) => Type === type);
      if (bookedFacility) {
        return {
          Date,
          ...bookedFacility,
        };
      } else {
        return {
          Date,
        };
      }
    });

    return bookedFacilities;
  };

  const checkForItinerary = (itineraryDates) => {
    setSelectionsObjects([]);
    itineraryDates.map(({ Date, Facilities }) => {
      Facilities.map(({ Type, Quantity, Time, Id, action }) => {
        if (Type === "Breakfast" || Type === "Dinner") {
          setSelectionsObjects((prevState) => [
            ...prevState,
            {
              Date: `${Date}T${Time}`,
              Identifiers: {
                IdentifierDate: Date,
                IdentifierType: Type,
              },
              Quantity,
              ReservationNumber: reservationNumber,
              LastName: lastName,
              ReservationId: reservationId,
              type: returnBookType(Type),
              id: Id,
              action: action,
              timeBooked: Time.substring(0, 5),
              isNotRequired: !Date || !Time || !Id,
            },
          ]);
        }
      });
    });
  };

  useEffect(() => {
    if (autoAllocateAll) return;

    getItinerary();
    getAvailability();
    getFacilities();
    return () => {
      setSelectionsObjects([]);
    };
  }, []);

  useEffect(() => {
    if (
      facilityData.length > 0 &&
      Object.keys(itineraryData).length > 0 &&
      (!availability || availability.length > 0)
    ) {
      setIsLoading(false);
    }
  }, [itineraryData, availability, facilityData]);
  return {
    itineraryData,
    availability,
    facilityData,
    isLoading,
    getItinerary,
    getAvailability,
  };
};

export default ItineraryLogic;
