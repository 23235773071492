const returnBookType = (type) =>
  type === "Breakfast" || type === "Dinner" || type === "Activity"
    ? "activity"
    : "spa";

const checkIfBooked = (breafastBooked, dinnerBooked) => {
  const bothBooked = breafastBooked && dinnerBooked;
  const oneBooked = breafastBooked || dinnerBooked;

  if (bothBooked) {
    return { color: "#2F5C27", txt: "Booked", icon: "done_success.svg", status: true };
  } else if (oneBooked) {
    return {
      color: "#FFA10A",
      txt: "Partially Booked",
      icon: "partially_booked_icon.svg",
      status: false
    };
  } else {
    return { color: "#AC1B1B", txt: "No Booking", icon: "error_failed.svg",status: false };
  }
};
export { returnBookType,checkIfBooked };
